<a class="toggle-button cursor-pointer" (click)="toggle = !toggle" *ngIf="artwork[previewFileKey]">
  <lib-salesonepro-icon [name]="toggle ? 'toggle-on' : 'toggle-off'" container="body" placement="top" ngbTooltip="Toggle Logo Background"></lib-salesonepro-icon>
</a>
<div [ngClass]="{'night-mode': toggle}" class="img-preview-wrap text-center p-1 border h-100" [appSpinner]="!artwork.loaded">
  <a class="cursor-pointer w-100" target="_blank" [href]="artwork[downloadFileKey]" *ngIf="artwork[previewFileKey]">
    <img class="m-auto mw-100" (load)="artwork.loaded = true" [hidden]="!artwork.loaded" 
    [src]="artwork[previewFileKey] ? artwork[previewFileKey] : noPreviewImg" (error)="imageLoadFail($event)" />
    <div class="middle" *ngIf="imageExist">
      <div class="middle-inner">
      <a class="btn btn-download d-block mb-2">Download Artwork</a>
      <a class="btn btn-download cursor-pointer d-block" target="_blank" [href]="artwork[previewFileKey]">
          Download Preview
      </a>
      </div>
    </div>
  </a> 
  <img class="m-auto mw-100" (load)="artwork.loaded = true" [hidden]="!artwork.loaded" 
  [src]= "noPreviewImg" (error)="imageLoadFail($event)" *ngIf="!artwork[previewFileKey]" /> 
</div>
