<ng-container *ngIf="!loadingDecorationOptions">
  <ng-container formGroupName="data">
    <ng-container [formGroupName]="decorationMethod">
      <div class="row">
        <div class="col-md-12 my-1">
          <table class="table table-bordered mb-0"
            *ngIf="threadDetails && threadDetails.length > 0">
            <thead>
              <tr>
                <th width="5%">#</th>
                <th width="20%">Color Type</th>
                <th width="35%">Color Code</th>
                <th width="30%">Color Description</th>
              </tr>
            </thead>
          </table>
          <div
            [ngClass]="{'table-wrapper-scroll-y': (threadDetails && threadDetails.length > 5)}">
            <table class="table table-bordered mb-0" *ngIf="threadDetails">
              <tbody formArrayName="color_details">
                <ng-container
                  *ngFor="let threadData of form.get(['color_details']).controls; let i=index">
                  <tr [formGroupName]="i">
                    <td width="5%">{{i+1}}</td>
                    <td width="20%" formGroupName="color_type">
                      <select class="form-control" formControlName="id"
                        (change)="threadChange($event, 'color_type', i)">
                        <option *ngFor="let item of threadTypeArray" [value]="item.id">{{item.data.swatch_text}}
                        </option>
                      </select>
                      <app-validation-errors [submitted]="formValidation"
                        [control]="threadData.get(['color_type', 'id'])"></app-validation-errors>
                    </td>
                    <td width="35%" class="position-relative" formGroupName="color">
                      <ng-select [items]="threadColorArray[i]"
                        (change)="onThreadColorSelect($event, i)" formControlName="id" bindLabel="code"
                        bindValue="id" placeholder="Select Color" class="color-code-wrap">
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                          <div class="float-start color-badge p-2 mr-2" [style.backgroundColor]="item.data.hex_code">
                          </div>
                          <div class="float-start font-sm mt-1 align-self-center"
                            *ngIf="item.data && item.data.color_name">
                            <p class="mb-0">
                              {{item.data.color_name}}
                            </p>
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td width="30%">
                      <input type="text" class="form-control" formControlName="color_description"
                        (input)="threadChange($event, 'color_description', i)" />
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>