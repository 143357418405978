/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalPopupOverlayRef } from '@salesonepro/components';
import { StorageService } from '@salesonepro/services';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-customer-popup',
  templateUrl: './customer-popup.component.html',
  styleUrls: ['./customer-popup.component.scss']
})
export class CustomerPopupComponent {

  arrMasterCustomer = [];
  arrCustomer = [];
  searchInput: string;
  selectedCustomerId: any;
  isLoadRefresh: boolean ;
  isLoading:boolean;

  constructor(
    @Inject(ModalPopupOverlayRef) public modalData,
    private router: Router,
    public dialogRef: ModalPopupOverlayRef<CustomerPopupComponent>,
    private storage: StorageService,
    private loginService: LoginService,
  ) {
    this.arrMasterCustomer = modalData.data.input.customer;
    this.isLoadRefresh = modalData.data.input.refresh;
    this.arrCustomer = this.arrMasterCustomer;
    if (this.storage.retrieve('selected_customer')) {
      this.selectedCustomerId = this.storage.retrieve('selected_customer').id;
    }
  }

  onClickCustomer(selectedCustomer) {
    this.isLoading = true;
    if (this.isLoadRefresh) this.storage.clear('account-details');
    this.storage.store('selected_customer', selectedCustomer);
    this.loginService.getCustomerShippingMethods();
    this.loginService.getCartSummary();
    if (this.isLoadRefresh) {
      window.location.reload();
    } else {
      this.router.navigateByUrl('/account/dashboard');
    }
    this.dialogRef.close(null);
  }

  onInputSearch(){
    if (this.searchInput) {
      this.arrCustomer = this.arrMasterCustomer.filter(
        (item) => item.company_name.toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1);
    } else {
      this.filterCustomer();
    }
  }

  filterCustomer() {
    this.arrCustomer = this.arrMasterCustomer;
  }

}