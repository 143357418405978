import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-artwork-preview',
  templateUrl: './artwork-preview.component.html',
})
export class ArtworkPreviewComponent implements OnInit {

  @Input() artwork: any = {
    org_preview_file: '',
    org_media_file: '',
    media_file: '',
    preview_thumbnail: ''
  };

  @Input() type = '';
  downloadFileKey = 'media_file';
  previewFileKey = 'preview_thumbnail';

  noPreviewImg = 'assets/images/no-preview.png';

  toggle: boolean;
  imageExist: boolean;

  ngOnInit(): void {
    if (this.type === 'original') {
      this.downloadFileKey = 'org_media_file';
      this.previewFileKey = 'org_preview_file';
    }
    this.imageExist = true;
  }

  /**
   * Triggered when original image fails to load
   */
  imageLoadFail(event) {
    event.target.width = 80;
    event.target.src = this.noPreviewImg;
    this.imageExist = false;
  }
}
