import {
  Component,
  Input,
  ContentChildren,
  QueryList,
  Directive,
  TemplateRef,
  AfterContentChecked,
  Output,
  EventEmitter
} from '@angular/core';

export interface TabsetConfig {
  justify: 'start' | 'center' | 'end' | 'fill' | 'justified';
  orientation: 'horizontal' | 'vertical';
  type: 'tabs' | 'pills';
}

let nextId = 0;

@Directive({selector: 'ng-template[appTabTitle]'})
export class TabTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({selector: 'ng-template[appTabContent]'})
export class TabContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({selector: 'app-tab'})
export class TabDirective implements AfterContentChecked {

  @Input() id = `app-tab-${nextId++}`;

  @Input() title: string;

  @Input() disabled = false;

  titleTpl: TabTitleDirective | null;
  contentTpl: TabContentDirective | null;

  @ContentChildren(TabTitleDirective, {descendants: false}) titleTpls: QueryList<TabTitleDirective>;
  @ContentChildren(TabContentDirective, {descendants: false}) contentTpls: QueryList<TabContentDirective>;

  ngAfterContentChecked() {
    this.titleTpl = this.titleTpls.first;
    this.contentTpl = this.contentTpls.first;
  }
}


export interface TabChangeEvent {

  activeId: string;
  nextId: string;
  preventDefault: () => void;
}

/**
 * A component that makes it easy to create tabbed interface.
 */
@Component({
  selector: 'app-tabset',
  exportAs: 'appTabset',
  template: `
    <ul [class]="'nav nav-' + type + (orientation === 'horizontal'?  ' ' + justifyClass : ' flex-column')" role="tablist">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a [id]="tab.id" class="nav-link" [class.active]="tab.id === activeId" [class.disabled]="tab.disabled"
          href (click)="select(tab.id); $event.preventDefault()" role="tab" [attr.tabindex]="(tab.disabled ? '-1': undefined)"
          [attr.aria-controls]="(!destroyOnHide || tab.id === activeId ? tab.id + '-panel' : null)"
          [attr.aria-selected]="tab.id === activeId" [attr.aria-disabled]="tab.disabled">
          {{tab.title}}<ng-template [ngTemplateOutlet]="tab.titleTpl?.templateRef"></ng-template>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <ng-template ngFor let-tab [ngForOf]="tabs">
        <div
          class="tab-pane {{tab.id === activeId ? 'active' : null}}"
          *ngIf="!destroyOnHide || tab.id === activeId"
          role="tabpanel"
          [attr.aria-labelledby]="tab.id" id="{{tab.id}}-panel">
          <ng-template [ngTemplateOutlet]="tab.contentTpl?.templateRef"></ng-template>
        </div>
      </ng-template>
    </div>
  `
})
export class TabsetComponent implements AfterContentChecked {
  justifyClass: string;

  @ContentChildren(TabDirective) tabs: QueryList<TabDirective>;

  @Input() activeId: string;
  @Input() destroyOnHide = true;
  @Input()
  set justify(className: 'start' | 'center' | 'end' | 'fill' | 'justified') {
    if (className === 'fill' || className === 'justified') {
      this.justifyClass = `nav-${className}`;
    } else {
      this.justifyClass = `justify-content-${className}`;
    }
  }
  @Input() orientation: 'horizontal' | 'vertical';
  @Input() type: 'tabs' | 'pills' | string;
  @Output() tabChange = new EventEmitter<TabChangeEvent>();
  config: TabsetConfig;

  constructor() {
    this.type = 'tabs';
    this.justify = 'start';
    this.orientation = 'horizontal';
  }

  select(tabId: string) {
    let selectedTab = this._getTabById(tabId);
    if (selectedTab && !selectedTab.disabled && this.activeId !== selectedTab.id) {
      let defaultPrevented = false;

      this.tabChange.emit(
          {activeId: this.activeId, nextId: selectedTab.id, preventDefault: () => { defaultPrevented = true; }});

      if (!defaultPrevented) {
        this.activeId = selectedTab.id;
      }
    }
  }

  ngAfterContentChecked() {
    let activeTab = this._getTabById(this.activeId);
    this.activeId = activeTab ? activeTab.id : (this.tabs.length ? this.tabs.first.id : null);
  }

  private _getTabById(id: string): TabDirective {
    let tabsWithId: TabDirective[] = this.tabs.filter(tab => tab.id === id);
    return tabsWithId.length ? tabsWithId[0] : null;
  }
}