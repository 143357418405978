import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, ControlContainer, Validators, FormArray } from '@angular/forms';

import { isEmpty } from '@salesonepro/utils';

@Component({
  selector: 'app-thread-details-form',
  templateUrl: './thread-details-form-injector.component.html',
  styleUrls: ['./thread-details-form-injector.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
})
export class ThreadDetailsFormInjectorComponent implements OnChanges {
  @Input() decorationMethod: string;
  @Input() logoDetails;
  @Input() logoThreadDetails;
  @Input() dstInfo;
  @Input() formValidation: boolean;
  @Input() noOfColors: number;
  @Input() threadColors: any = [];
  @Input() threadTypeArray: any = [];
  @Input() defaultThreadType: number;
  @Input() orderOptions: any;
  @Input() isPriceReady: boolean;
  @Input() loadingDecorationOptions: boolean;
  @Output() resetDst = new EventEmitter();
  @Output() logoThreadDetailsChange = new EventEmitter();
  @Output() selectedIndexChange = new EventEmitter();
  @Output() dstColorChange = new EventEmitter();

  form: FormGroup;

  selectedThreadColor: any = [];
  threadColorArray: any = [];
  dstColors: any = {};
  intervalListener: any;
  showThreadColorOption: any = [];
  embDecorationList = [
    'flat_embroidery',
    'three_dimension_embroidery',
    'text_embroidery'
  ];

  resetCanvas: boolean;

  constructor(
    private parent: FormGroupDirective,
    private fb: FormBuilder,
    private el: ElementRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.decorationMethod && this.decorationMethod && this.form) {
      this.logoThreadDetails = [];
      this.form = null;
    }
    if (!this.parent.form.get(['data'])) {
      this.parent.form.addControl('data', this.fb.group({}));
    }
    if (!this.parent.form.get(['data', this.decorationMethod])) {
      const dataFormGp = this.parent.form.get('data') as FormGroup;
      dataFormGp.addControl(this.decorationMethod, this.fb.group({}));
    }

    if (!this.form) {
      this.form = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
    }
    if (!this.form.get(['thread_details'])) {
      const decorationFormGroup = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
      decorationFormGroup.addControl('thread_details', new FormArray([]));
    }
    if (this.form) {
      if (changes.dstInfo && this.dstInfo && !changes.logoThreadDetails) {
        this.logoThreadDetails = [];
        const threadDetailsArray = this.form.get(['thread_details']) as FormArray;
        threadDetailsArray.controls = [];
        const internalColorExist = this.checkInternalColor();
        this.dstInfo.color_stich_array.forEach((stitch, index) => {
          let threadTypeId = '';
          const defaultThread = this.threadTypeArray.find(x => x.id === this.defaultThreadType);
          if (defaultThread) {
            threadTypeId = defaultThread.id;
            const threadTypeName = defaultThread.data.value;
            const allowedColors = this.getAllowedColors(threadTypeName, internalColorExist);
            this.threadColorArray[index] = allowedColors;
          } else {
            threadTypeId = this.threadTypeArray[0];
            const threadTypeName = this.threadTypeArray[0].data.value;
            const allowedColors = this.getAllowedColors(threadTypeName, internalColorExist);
            this.threadColorArray[index] = allowedColors;
          }
          const colorId = this.threadColorArray[index][index] ? this.threadColorArray[index][index]['id'] : this.threadColorArray[index][0]['id'];
          const tmp = {
            stitch_count: 0,
            color_type: {
              id: threadTypeId
            },
            color: {
              id: colorId
            },
            color_description: ''
          };
          this.showThreadColorOption[index] = false;
          this.logoThreadDetails.push(tmp);
          const threadDetailsForm = this.initThreadForm(tmp);
          threadDetailsArray.push(threadDetailsForm);
          const selectedColor = this.getThreadColorDetails(this.threadColorArray[index], colorId);
          if (selectedColor) {
            this.setThreadColor(selectedColor, index);
          }
        });
      }
      if (!this.loadingDecorationOptions
        && this.decorationMethod && this.logoDetails
        && this.logoDetails['data'][this.decorationMethod]
        && this.logoDetails['data'][this.decorationMethod]['thread_details']
      ) {

        this.logoThreadDetails = this.logoDetails['data'][this.decorationMethod]['thread_details'];
        const threadDetailsArray = this.form.get(['thread_details']) as FormArray;
        threadDetailsArray.controls = [];
        const internalColorExist = this.checkInternalColor();
        this.logoThreadDetails.forEach((element, index) => {
          const threadDetailsForm = this.initThreadForm(element);
          threadDetailsArray.push(threadDetailsForm);
          const allowedColors = this.getThreadColors(index, internalColorExist);
          if (allowedColors && element.color && element.color.id) {
            const selectedColor = allowedColors.find(x => x.id === element.color.id);
            this.setThreadColor(selectedColor, index);
            this.threadColorArray[index] = allowedColors;
          } else {
            this.setThreadColor({}, index);
          }
        });
      }
      if (changes.noOfColors && !this.loadingDecorationOptions) {
        this.onColorChange(this.noOfColors);
      }
    }
  }

  checkInternalColor() {
    let internalColorExist = false;
    if (this.logoDetails && this.logoDetails['data'] && this.logoDetails['data'][this.decorationMethod] && this.logoDetails['data'][this.decorationMethod]['thread_details']) {
      if (this.logoDetails['data'][this.decorationMethod]['thread_details'].find(x => x.color && x.color.is_internal)) {
        internalColorExist = true;
      }
    }

    return internalColorExist;
  }

  getAllowedColors(threadTypeName: string, includeInternal: boolean = false) {
    if (!includeInternal) {
      return this.threadColors.filter(x => x.data.color_type === threadTypeName && !x.is_internal);
    }
    return this.threadColors.filter(x => x.data.color_type === threadTypeName);
  }

  groupByFn = (item) => item.data.group;
  get threadDetailsFormData() { return this.form.get(['thread_details']) as FormArray; }

  initThreadForm(data?) {
    if (data) {
      return this.fb.group({
        'stitch_count': [data.stitch_count],
        'color_description': [data.color_description],
        'color_type': this.fb.group({
          'id': [data.color_type?.id, Validators.required],
        }),
        'color': this.fb.group({
          'id': [data.color?.id, Validators.required],
        })
      });
    } else {
      return this.fb.group({
        'stitch_count': [''],
        'color_description': [''],
        'color_type': this.fb.group({
          'id': [''],
        }),
        'color': this.fb.group({
          'id': [''],
        })
      });
    }
  }

  focusChange(i) {
    this.selectedIndexChange.emit(i);
  }

  onColorChange(value) {
    const prevData = this.logoThreadDetails ? this.logoThreadDetails : [];
    this.logoThreadDetails = [];
    const threadDetailsArray = this.form.get(['thread_details']) as FormArray;
    threadDetailsArray.controls = [];

    if (value > 0) {
      for (let i = 0; i < value; i++) {
        const index = i;
        if (!prevData[index]) {
          let threadTypeId = '';
          const defaultThread = this.threadTypeArray.find(x => x.id === this.defaultThreadType);
          if (defaultThread) {
            threadTypeId = defaultThread.id;
            const threadTypeName = defaultThread.data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName && !x.is_internal);
            this.threadColorArray[index] = allowedColors;
          } else if (this.threadTypeArray.length > 0) {
            threadTypeId = this.threadTypeArray[0];
            const threadTypeName = this.threadTypeArray[0].data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName && !x.is_internal);
            this.threadColorArray[index] = allowedColors;
          }
          const selectedColor = this.threadColorArray[index][i] ? this.threadColorArray[index][i] : this.threadColorArray[index][0];
          const tmp = {
            stitch_count: 0,
            color_type: {
              id: threadTypeId
            },
            color: {
              id: selectedColor['id']
            },
            color_description: ''
          };
          this.logoThreadDetails.push(tmp);
          const threadDetailsForm = this.initThreadForm(tmp);
          threadDetailsArray.push(threadDetailsForm);
          if (selectedColor) {
            this.setThreadColor(selectedColor, index);
          }
        } else {
          const colorTypeId = prevData[index]?.['color_type']?.['id'];
          const defaultThread = colorTypeId ? this.threadTypeArray.find(x => x.id === parseInt(colorTypeId, 10)) : null;
          // const defaultThread = this.threadTypeArray.find(x => x.id === parseInt(prevData[index]['color_type']['id']));
          if (defaultThread) {
            const threadTypeName = defaultThread.data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[index] = allowedColors;
            this.logoThreadDetails.push(prevData[index]);
            const threadDetailsFormData = this.initThreadForm(prevData[index]);
            threadDetailsArray.push(threadDetailsFormData);
          } else if (this.threadTypeArray.length > 0) {
            const defaultThread = this.threadTypeArray[0];
            const threadTypeName = defaultThread.data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[index] = allowedColors;
            const tmp = {
              stitch_count: 0,
              color_type: {
                id: defaultThread['id']
              },
              color: {
                id: this.threadColorArray[index][i] ? this.threadColorArray[index][i]['id'] : this.threadColorArray[index][0]['id']
              },
              color_description: ''
            };

            this.logoThreadDetails.push(tmp);
            const threadDetailsFormData = this.initThreadForm(tmp);
            threadDetailsArray.push(threadDetailsFormData);
          }
        }
      }
    } else {
      threadDetailsArray.clear();
    }
  }

  getThreadColorDetails(threadColors, id) {
    return threadColors.find(x => x.id === id);
  }


  setThreadColor(selectedColor, index) {
    if (isEmpty(selectedColor)) {
      this.form.get(['thread_details', index, 'color', 'id']).setValue('');
      this.selectedThreadColor[index] = '';
      this.dstColors[index] = '';
    } else {
      this.form.get(['thread_details', index, 'color', 'id']).setValue(selectedColor.id);
      this.selectedThreadColor[index] = selectedColor.data.color_name;
      this.dstColors[index] = selectedColor.data.hex_code !== '' ? selectedColor.data.hex_code : '#000';
    }

    this.dstColorChange.emit(this.dstColors);
  }

  onThreadColorSelect(selectedColor, index) {
    if (selectedColor) {
      if (this.logoThreadDetails[index]['color'] && this.logoThreadDetails[index]['color']['id']) {
        this.logoThreadDetails[index]['color'].id = selectedColor.id;
      } else {
        this.logoThreadDetails[index]['color'] = {
          id: selectedColor.id
        };
      }
      this.setThreadColor(selectedColor, index);
      this.logoThreadDetailsChange.emit({
        logoThreadDetails: this.logoThreadDetails,
        selectedColor: selectedColor,
        index: index
      });
    }
  }

  getThreadColors(index, includeInternal: boolean = false) {
    const threadTypeId = this.threadDetailsFormData.get([index, 'color_type', 'id']).value;
    const defaultThread = this.threadTypeArray.find(x => parseInt(x.id) === parseInt(threadTypeId));
    const threadTypeName = defaultThread?.data?.value;
    if (!includeInternal) {
      return this.threadColors.filter(x => x.data.color_type === threadTypeName && !x.is_internal);
    } else {
      return this.threadColors.filter(x => x.data.color_type === threadTypeName);
    }
  }

  threadChange(event, key, index) {
    if (key === 'color_description') {
      this.logoThreadDetails[index][key] = event.target.value;
    }
    if (key === 'color_type') {
      const allowedColors = this.getThreadColors(index);
      this.logoThreadDetails[index]['color_type']['id'] = event.target.value;
      if (this.logoThreadDetails[index]['color'] && this.logoThreadDetails[index]['color']['id']) {
        this.logoThreadDetails[index]['color']['id'] = allowedColors[0].id ? allowedColors[0].id : '';
      } else {
        this.logoThreadDetails[index]['color'] = {
          id: allowedColors[0].id ? allowedColors[0].id : '',
        };
      }

      if (allowedColors) {
        this.threadColorArray[index] = allowedColors;
      } else {
        this.threadColorArray[index] = [];
      }
      if (this.threadColorArray[index] && this.threadColorArray[index][0]) {
        const selectedColor = this.threadColorArray[index][0];
        this.setThreadColor(selectedColor, index);
      } else {
        this.setThreadColor('', index);
      }
      this.selectedIndexChange.emit(index);
      setTimeout(() => {
        this.dstDrawReset();
      });
    }
  }

  dstDrawReset() {
    this.resetDst.emit();
  }
}
