<div class="modal-dialog modal-{{size}} modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header {{headerClass}}">
      <h4 class="modal-title">{{ title }}</h4>
      <button *ngIf="isShowCloseButton" type="button" class="close text-white" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true"><app-icon name="x-lg"></app-icon></span>
      </button>
    </div>
    <div class="modal-body {{bodyClass}}">
      <ng-container [ngSwitch]="contentType">
        <ng-container *ngSwitchCase="'string'">
          <div [innerHTML]="content"></div>
        </ng-container>
    
        <ng-container *ngSwitchCase="'template'">
          <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
        </ng-container>
    
        <ng-container *ngSwitchCase="'component'">
          <ng-container *ngComponentOutlet="content"></ng-container>
        </ng-container>
    
      </ng-container>
    </div>
  </div>
</div>