import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {
  PaginationComponent,
  PaginationEllipsisDirective,
  PaginationFirstDirective,
  PaginationLastDirective,
  PaginationNextDirective,
  PaginationNumberDirective,
  PaginationPreviousDirective
} from './pagination';

export {
  PaginationComponent,
  PaginationEllipsisDirective,
  PaginationFirstDirective,
  PaginationLastDirective,
  PaginationNextDirective,
  PaginationNumberDirective,
  PaginationPreviousDirective
} from './pagination';

const DIRECTIVES = [
  PaginationComponent,
  PaginationEllipsisDirective,
  PaginationFirstDirective,
  PaginationLastDirective,
  PaginationNextDirective,
  PaginationNumberDirective,
  PaginationPreviousDirective
];

@NgModule({declarations: DIRECTIVES, exports: DIRECTIVES, imports: [CommonModule]})
export class PaginationModule {}