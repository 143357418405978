import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// third-party
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  breadcrumbs:any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.breadcrumbs = [];
      let currentRoute = this.route.root;
      let url = '';
      while (currentRoute) {
        const childrenRoutes = currentRoute.children;
        currentRoute = null;

        childrenRoutes.forEach(route => {
          if (route.outlet === 'primary') {
            const routeSnapshot = route.snapshot;
            const currentUrl = routeSnapshot.url.map(segment => segment.path).join('/');
            if (currentUrl) {
              url += `/${currentUrl}`;
            }
            const normalizedUrl = url.replace(/\/+$/, '');
            const breadcrumb = route.snapshot.data.breadcrumb;
            const status = route.snapshot.data.status !== undefined ? route.snapshot.data.status : true;
            if (breadcrumb && !this.breadcrumbs.some(bc => bc.label === breadcrumb && bc.url === normalizedUrl)) {
              this.breadcrumbs.push({
                label: breadcrumb,
                status: status,
                url: normalizedUrl
              });
            }
            currentRoute = route;
          }
        });
      }
    });
  }

}
