
import {of as observableOf,  Observable } from 'rxjs';

import {catchError, map, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, Route } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.authService.isAuthenticated.pipe(map(
        r => {
          const idToken = this.authService.getToken();
          const tokenExpired = idToken ? this.authService.isTokenExpired() : true;
          if (r && !tokenExpired) {
            // logged in so return true
            return true;
          }
          this.router.navigate(['/login']);
          return false;
        }),
        catchError((error: any) => {
          this.router.navigateByUrl('/login');
          return observableOf(false);
        }));
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    // add logic for permission
    return observableOf(true);
  }

  checkLogin(url: string): boolean {
    if (this.authService.isAuthenticated.pipe(take(1), map(bool => !bool))) { return true; }

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    // Create a dummy session id
    const sessionId = 123456789;

    // Set our navigation extras object
    // that contains our global query params and fragment
    const navigationExtras: NavigationExtras = {
      queryParams: { 'session_id': sessionId },
      fragment: 'anchor'
    };

    // Navigate to the login page with extras
    this.router.navigate(['/login'], navigationExtras);
    return false;
  }

}
