import { Component, OnInit } from '@angular/core';
import { AuthService } from './salesonepro-common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'penn-cp';

  constructor (
    private authService: AuthService
  ) {
  }

  ngOnInit(){
    this.authService.populate();
  }
}
