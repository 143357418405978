<audio #audioplayer
[src]="list"
[autoplay]="autoplay"
[controls]="controls"
[volume]="volume"
[loop]="loop"
[preload]="preload"
[muted]="muted"
>
</audio>
<button type="button" (click)="play()" *ngIf="playButton" translate>Play</button>
<button type="button" (click)="pause()" *ngIf="pauseButton" translate>Pause</button>
<button type="button" (click)="previousTrack()" *ngIf="selectableButton" translate>Previous</button>
<button type="button" (click)="nextTrack()" *ngIf="selectableButton" translate>Next</button>
<button type="button" (click)="muteVideo()" *ngIf="muteButton" translate>Mute</button>