import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  timeOut = 3000;

  constructor(
    private toastr: ToastrService
  ) {
  }

  success(message: string, title: string = 'Success!') {
    this.toastr.success(message, title, {
      timeOut: this.timeOut
    });
  }

  showSuccessTemplate(type: string, title: string) {
    let message = title;
    if (type === 'add') {
      message += ' added successfully';
    } else if (type === 'update') {
      message += ' updated successfully';
    } else if (type === 'delete') {
      message += ' deleted successfully';
    } else {
      message += ` ${type} successfully`;
    }

    this.toastr.success(message, 'Success!', {
      timeOut: this.timeOut
    });
  }

  info(message: string, title?, config?) {
    if (!config) {
      config = {
        timeOut: this.timeOut
      };
    }
    this.toastr.info(message, title, config);
  }

  warning(message: string) {
    this.toastr.warning(message, '', {
      timeOut: this.timeOut
    });
  }

  error(message: string, title: string = 'Error!') {
    this.toastr.error(message, title, {
      timeOut: this.timeOut
    });
  }

}
