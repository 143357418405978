import { Subject } from 'rxjs';

import { OverlayRef } from '@angular/cdk/overlay';

import { TemplateRef, Type } from '@angular/core';

export interface ModalConfig {
  title?: string,
  hideBackdrop?: boolean,
  panelClass?: string,
  size?: string,
  headerClass?: string;
  bodyClass?: string;
  position?: string,
  disableBackdropClick?: boolean,
  input?: any,
  hideCloseButton?: boolean;
}

export interface OverlayCloseEvent<R> {
  data: R;
}

// R = Response Data Type, T = Data passed to Modal Type
export class ModalPopupOverlayRef<R = any, T = ModalConfig> {
  afterClosed$ = new Subject<OverlayCloseEvent<R>>();

  constructor(
    public overlay: OverlayRef,
    public content: string | TemplateRef<any> | Type<any>,
    public data: ModalConfig// pass data to modal
  ) { }

  close(data?: R) {
    document.querySelector('body').classList.remove('modal-popup-open');
    this._close(data);
  }

  private _close(data: R) {
    this.overlay.dispose();
    this.afterClosed$.next({
      data
    });

    this.afterClosed$.complete();
  }
}