import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  unitOfMeasurement = 'metric';

  constructor(
    private storage: StorageService,
  ) { }

  /**
   * Used to format date
   */
  formatDate(currentDate, format = 'yyyy-MM-dd') {
    if (currentDate) {
      const date = new Date(currentDate);
      const modifiedDate = formatDate(date, format, 'en');

      return modifiedDate;
    }
    return currentDate;
  }

  /**
   * Used to format date time
   */
  formatDateTime(currentDateTime, format = 'yyyy-MM-dd HH:mm:ss') {
    if (currentDateTime) {
      const date = new Date(currentDateTime);
      const modifiedDate = formatDate(date, format, 'en');

      return modifiedDate;
    }

    return currentDateTime;
  }


  /**
   * Used to format  time
   */
  formatTime(currentDateTime, format = 'HH:mm:ss') {
    if (currentDateTime) {
      const date = new Date(currentDateTime);
      const modifiedDate = formatDate(date, format, 'en');

      return modifiedDate;
    }

    return currentDateTime;
  }
  formatDisplayDate(currentDate, format = 'MM/dd/yyyy') {
    if (currentDate) {
      const date = new Date(currentDate);
      const modifiedDate = formatDate(date, format, 'en');

      return modifiedDate;
    }
    return currentDate;
  }

  getDateObject(dateObj, hourString) {
    const arr = hourString.split(':');
    const d = new Date(dateObj);
    d.setHours(arr[0], arr[1], arr[2], 0);
    return d;
  }

  getCvvMessage(code: string) {
    const messageData = {
      'M': 'CVV Match',
      'N': 'CVV No Match',
      'P': 'Not Processed',
      'S': 'Should have been present',
      'U': 'Unsupported by issuer',
      'I': 'Invalid',
      'Y': 'Invalid'
    };

    if (code && code !== '' && messageData[code]) {
      return messageData[code];
    } else {
      return 'Not applicable';
    }
  }

  convertSecondToDisplay(sec) {
    let seconds = Math.floor(sec);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    hours = hours - (days * 24);
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

    const returnString = [];
    if (days > 0) {
      returnString.push(days + ' day' + (days !== 1 ? 's' : ''));
    }
    if (hours > 0) {
      returnString.push(hours + ' hour' + (hours !== 1 ? 's' : ''));
    }
    if (minutes > 0) {
      returnString.push(minutes + ' minute' + (minutes !== 1 ? 's' : ''));
    }
    if (seconds > 0) {
      returnString.push(seconds + ' second' + (seconds !== 1 ? 's' : ''));
    }

    return returnString.join(', ');
  }

  setTimeForFilterDate(dateObj, filterType = 'from date') {
    const d = new Date(dateObj);
    if (filterType === 'from date') {
      d.setHours(0, 0, 0, 0);
    } else {
      d.setHours(23, 59, 59, 0);
    }
    return d;
  }

  /**Used to format Unit**/
  formatUnit(value, args) {
    if (this.storage.retrieve('unitOfMeasurement')) {
      this.unitOfMeasurement = this.storage.retrieve('unitOfMeasurement');
    }

    if (value !== 0) {
      if (this.unitOfMeasurement === 'imperial') {
        return parseFloat(value).toFixed(5);
      } else if (this.unitOfMeasurement === 'metric') {
        if (args === 'ft') {
          return (value / 2.54).toFixed(5); // cm to inch
        } else if (args === 'cu ft') {
          return (value / 16.3871).toFixed(5); // cubic cm to cubic inch
        } else if (args === 'lb') {
          return (value / 453.5970244035199).toFixed(5); // gram to pound
        }
      }
    } else {
      return value;
    }
  }
}
