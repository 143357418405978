<ng-container [formGroup]="data">
  <ng-container [formGroupName]="decorationType">
    <div class="row">
      <div class="col-md-3 my-2">
        <label for="logo_width" class="font-xs">Logo Size (Width) <small class="text-danger">*</small></label>
        <input type="number" min="0" [readonly]="isReadOnly" id="logo_width" class="form-control"
          (input)="onDimensionChange($event.target.value, 'logo_width')" formControlName="logo_width"
          [placeholder]="checkProportional('logo_width') ? 'Proportional' : ''">
        <small *ngIf="checkProportional('logo_width')" class="form-text text-primary">Proportional to height.</small>
        <app-validation-errors [submitted]="invalidForm"
          [control]="form.get(['data', decorationType, 'logo_width'])"></app-validation-errors>
        <app-server-validation-error [errors]="error" key="logo_width"></app-server-validation-error>
      </div>
      <div class="col-md-3 my-2">
        <label for="logo_size" class="font-xs">Logo Size (Height) <small class="text-danger">*</small></label>
        <input type="number" min="0" [readonly]="isReadOnly" id="logo_size" class="form-control"
          (input)="onDimensionChange($event.target.value, 'logo_size')" formControlName="logo_size"
          [placeholder]="checkProportional('logo_size') ? 'Proportional' : ''">
        <small *ngIf="checkProportional('logo_size')" class="form-text text-primary">Proportional to width.</small>
        <app-validation-errors [submitted]="invalidForm"
          [control]="form.get(['data', decorationType, 'logo_size'])"></app-validation-errors>
        <app-server-validation-error [errors]="error" key="logo_size"></app-server-validation-error>
      </div>
      <div class="col-md-3 my-2">
        <label for="no_of_colors"># of Colors <small class="text-danger">*</small></label>
        <input type="number" min="0" [readonly]="isReadOnly" id="no_of_colors"
          (input)="onInputChange($event.target.value)" class="form-control" formControlName="no_of_colors">
        <app-validation-errors [submitted]="invalidForm"
          [control]="form.get(['data', decorationType, 'no_of_colors'])"></app-validation-errors>
        <app-server-validation-error [errors]="error" key="no_of_colors"></app-server-validation-error>
      </div>
      <div class="col-md-3 my-2">
        <label for="no_of_flash"># of Flash <small class="text-danger">*</small></label>
        <input type="number" min="0" [readonly]="isReadOnly" id="no_of_flash" class="form-control"
          formControlName="no_of_flash">
        <app-validation-errors [submitted]="invalidForm"
          [control]="form.get(['data', decorationType, 'no_of_flash'])"></app-validation-errors>
        <app-server-validation-error [errors]="error" key="no_of_flash"></app-server-validation-error>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 my-2">
        <label for="customer_logo_price">Customer Logo Price <small class="text-danger">*</small></label>
        <input type="number" min="0" [readonly]="isReadOnly" id="customer_logo_price" class="form-control"
          formControlName="customer_logo_price">
        <app-validation-errors [submitted]="invalidForm"
          [control]="form.get(['data', decorationType, 'customer_logo_price'])"></app-validation-errors>
        <app-server-validation-error [errors]="error" key="customer_logo_price"></app-server-validation-error>
      </div>
      <div class="col-md-3 my-2">
        <label for="vendor_logo_price">Vendor Logo Price <small class="text-danger">*</small></label>
        <input type="number" min="0" [readonly]="isReadOnly" id="vendor_logo_price" class="form-control"
          formControlName="vendor_logo_price">
        <app-validation-errors [submitted]="invalidForm"
          [control]="form.get(['data', decorationType, 'vendor_logo_price'])"></app-validation-errors>
        <app-server-validation-error [errors]="error" key="vendor_logo_price"></app-server-validation-error>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_artwork_charge" class="form-check-input"
            formControlName="is_artwork_charge">
          <label for="is_artwork_charge" class="form-check-label">Artwork Charge</label>
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_artwork_required" class="form-check-input"
            formControlName="is_artwork_required">
          <label for="is_artwork_required" class="form-check-label">Artwork Required</label>
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_film_charge" class="form-check-input" formControlName="is_film_charge">
          <label for="is_film_charge" class="form-check-label">Film Charge</label>
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_flash_charge" class="form-check-input" formControlName="is_flash_charge">
          <label for="is_flash_charge" class="form-check-label">Flash Charge</label>
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_nylon_item_charge" class="form-check-input"
            formControlName="is_nylon_item_charge">
          <label for="is_nylon_item_charge" class="form-check-label">Nylon Item Charge</label>
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_pms_matching_charge" class="form-check-input"
            formControlName="is_pms_matching_charge">
          <label for="is_pms_matching_charge" class="form-check-label">PMS Matching Charge</label>
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_reorder_setup_charge" class="form-check-input"
            formControlName="is_reorder_setup_charge">
          <label for="is_reorder_setup_charge" class="form-check-label">Reorder Setup Charge</label>
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_screen_setup" class="form-check-input" formControlName="is_screen_setup">
          <label for="is_screen_setup" class="form-check-label">Screen Setup</label>
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_special_ink" class="form-check-input" formControlName="is_special_ink">
          <label for="is_special_ink" class="form-check-label">Special Ink</label>
        </div>
      </div>
      <div class="col-md-3 my-2">
        <div class="form-check">
          <input type="checkbox" id="is_speciality_item_charge" class="form-check-input"
            formControlName="is_speciality_item_charge">
          <label for="is_speciality_item_charge" class="form-check-label">Speciality Item Charge</label>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>