<ng-container *ngIf="addressData">
  <div class="row">
    <div class="col-lg-1">
      <i class="bi bi-person-fill"></i>
    </div>
    <div class="col-lg-9">
      <p class="mb-0">Name</p>
      <h5>{{addressData.name}}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-1">
      <i class="bi bi-wrench"></i>
    </div>
    <div class="col-lg-9">
      <p class="mb-0">Company</p>
      <h5>{{addressData.company_name}}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-1">
      <i class="bi bi-geo-alt-fill"></i>
    </div>
    <div class="col-lg-9">
      <p class="mb-0">Address</p>
      <h5>
        {{addressData.address_1}}{{addressData.address_2 ? ', ' + addressData.address_2 : ''}},
        {{addressData.city}}, {{addressData.state}} {{addressData.zip}}, {{addressData.country}}
      </h5>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-1">
      <i class="bi bi-telephone-fill"></i>
    </div>
    <div class="col-lg-9">
      <p class="mb-0">Phone</p>
      <h5>{{addressData.phone}}</h5>
    </div>
  </div>
</ng-container>