import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { DecorationTypeFormComponent } from '../../form.component';

@Component({
  selector: 'app-order-screen-printing-form',
  templateUrl: './form.component.html'
})
export class ScreenPrintingFormComponent extends DecorationTypeFormComponent {
  override decorationType = 'screen_printing';

  override initForm() {
    const decorationFormGroup = this.form.get(['data', this.decorationType]) as FormGroup;
    decorationFormGroup.addControl('no_of_colors', new FormControl('', [Validators.required, Validators.min(1)]));
    decorationFormGroup.addControl('customer_logo_price', new FormControl('', [Validators.required, Validators.min(0)]));
    decorationFormGroup.addControl('vendor_logo_price', new FormControl('', [Validators.required, Validators.min(0)]));
    decorationFormGroup.addControl('no_of_flash', new FormControl('', [Validators.required, Validators.min(0)]));
    decorationFormGroup.addControl('is_artwork_charge', new FormControl(false));
    decorationFormGroup.addControl('is_artwork_required', new FormControl(false));
    decorationFormGroup.addControl('is_film_charge', new FormControl(false));
    decorationFormGroup.addControl('is_flash_charge', new FormControl(false));
    decorationFormGroup.addControl('is_nylon_item_charge', new FormControl(false));
    decorationFormGroup.addControl('is_pms_matching_charge', new FormControl(false));
    decorationFormGroup.addControl('is_reorder_setup_charge', new FormControl(false));
    decorationFormGroup.addControl('is_screen_setup', new FormControl(false));
    decorationFormGroup.addControl('is_special_ink', new FormControl(false));
    decorationFormGroup.addControl('is_speciality_item_charge', new FormControl(false));
    decorationFormGroup.addControl('color_details', new FormArray([]));
    decorationFormGroup.get(['no_of_colors']).valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe((value) => {
        this.colorChange.emit(value);
      });
  }

  override renderDstData() {
    const controls = this.form.get(['data', this.decorationType]) as FormGroup;
    controls.get(['no_of_colors']).setValue(parseInt(this.artworkSharedData.no_of_colors, 10));
  }

  override loadLogoDetails(embroidery) {
    const controls = this.form.get(['data', this.decorationType]) as FormGroup;
    controls.get(['no_of_colors']).setValue(embroidery['no_of_colors'] ? parseInt(embroidery['no_of_colors'], 10) : this.noOfColors);
    controls.get(['no_of_flash']).setValue(embroidery['no_of_flash'] ? parseInt(embroidery['no_of_flash'], 10) : '');
    controls.get(['customer_logo_price']).setValue(embroidery['customer_logo_price'] ? parseFloat(embroidery['customer_logo_price']) : 0);
    controls.get(['vendor_logo_price']).setValue(embroidery['vendor_logo_price'] ? parseFloat(embroidery['vendor_logo_price']) : this.noOfColors);
    controls.get(['is_artwork_charge']).setValue(embroidery['is_artwork_charge'] ? embroidery['is_artwork_charge'] : false);
    controls.get(['is_artwork_required']).setValue(embroidery['is_artwork_required'] ? embroidery['is_artwork_required'] : false);
    controls.get(['is_film_charge']).setValue(embroidery['is_film_charge'] ? embroidery['is_film_charge'] : false);
    controls.get(['is_flash_charge']).setValue(embroidery['is_flash_charge'] ? embroidery['is_flash_charge'] : false);
    controls.get(['is_nylon_item_charge']).setValue(embroidery['is_nylon_item_charge'] ? embroidery['is_nylon_item_charge'] : false);
    controls.get(['is_pms_matching_charge']).setValue(embroidery['is_pms_matching_charge'] ? embroidery['is_pms_matching_charge'] : false);
    controls.get(['is_reorder_setup_charge']).setValue(embroidery['is_reorder_setup_charge'] ? embroidery['is_reorder_setup_charge'] : false);
    controls.get(['is_screen_setup']).setValue(embroidery['is_screen_setup'] ? embroidery['is_screen_setup'] : false);
    controls.get(['is_special_ink']).setValue(embroidery['is_special_ink'] ? embroidery['is_special_ink'] : false);
    controls.get(['is_speciality_item_charge']).setValue(embroidery['is_speciality_item_charge'] ? embroidery['is_speciality_item_charge'] : false);
    controls.get(['color_details']).setValue([]);
  }

}
