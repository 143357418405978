import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { DecorationTypeFormComponent } from '../../form.component';

@Component({
  selector: 'app-order-flat-embroidery-form',
  templateUrl: './form.component.html'
})
export class FlatEmbroideryFormComponent extends DecorationTypeFormComponent {
  override decorationType = 'flat_embroidery';

  override initForm() {
    const decorationFormGroup = this.form.get(['data', this.decorationType]) as FormGroup;
    decorationFormGroup.addControl('no_of_colors', new FormControl('', [Validators.required, Validators.min(1)]));
    decorationFormGroup.addControl('total_stitch_count', new FormControl(''));
    decorationFormGroup.addControl('is_digitizing_required', new FormControl(false));
    decorationFormGroup.addControl('is_neon_thread', new FormControl(false));
    decorationFormGroup.addControl('is_tape_edit', new FormControl(false));
    decorationFormGroup.addControl('use_digitized_data', new FormControl(false));
    decorationFormGroup.addControl('is_special_location', new FormControl(false));
    decorationFormGroup.addControl('is_match_tone_charge', new FormControl(false));

    decorationFormGroup.get(['no_of_colors']).valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe((value) => {
        this.colorChange.emit(value);
      });
  }

  override renderDstData() {
    const controls = this.form.get(['data', this.decorationType]) as FormGroup;
    controls.get(['no_of_colors']).setValue(parseInt(this.artworkSharedData.no_of_colors, 10));
    controls.get(['total_stitch_count']).setValue(this.artworkSharedData.total_stitch_count);
  }

  override loadLogoDetails(embroidery) {
    const controls = this.form.get(['data', this.decorationType]) as FormGroup;
    controls.get(['no_of_colors']).setValue(embroidery['no_of_colors'] ? parseInt(embroidery['no_of_colors'], 10) : this.noOfColors);
    controls.get(['total_stitch_count']).setValue(embroidery['total_stitch_count'] ? embroidery['total_stitch_count'] : this.totalStitchCount);
    controls.get(['is_digitizing_required']).setValue(embroidery['is_digitizing_required'] ? embroidery['is_digitizing_required'] : false);
    controls.get(['is_neon_thread']).setValue(embroidery['is_neon_thread'] ? embroidery['is_neon_thread'] : false);
    controls.get(['is_tape_edit']).setValue(embroidery['is_tape_edit'] ? embroidery['is_tape_edit'] : false);
    controls.get(['is_special_location']).setValue(embroidery['is_special_location'] ? embroidery['is_special_location'] : false);
    controls.get(['is_tape_edit']).setValue(embroidery['is_tape_edit'] ? embroidery['is_tape_edit'] : false);
    controls.get(['is_match_tone_charge']).setValue(embroidery['is_match_tone_charge'] ? embroidery['is_match_tone_charge'] : false);
    // controls.get(['thread_details']).setValue([]);
  }

}
