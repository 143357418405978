<div class="header-wrap bg-primary text-white py-2">
  <div class="theme-container">
    <div class="d-flex justify-content-between align-items-center">
      <div class="">
        <i class="bi bi-telephone me-2"></i>
        <span>800-793-7366</span>
      </div>
      <div class="d-flex align-items-center">
        <div class="login-form-wrap me-4" *ngIf="!currentUser['name']">
          <a href="#" class="text-light text-decoration-none"> Login</a>
        </div>
        <div class="dropdown cursor-pointer me-4" *ngIf="currentUser['name']">
          <div class="d-flex align-items-center" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false">
            <div class="img-avatar position-relative text-acronym float-start">
              <span class="d-flex name-text justify-content-center">{{userAcronym}}</span>
            </div>
            <span class="mx-1 d-none d-sm-flex" title="{{ currentUser['name'].length > 15 ? currentUser['name'] : ''}}">
              {{currentUser['name'].length > 15 ? (currentUser['name'] | slice:0:15) + '...' : currentUser['name'] }}
            </span>
            <i class="bi bi-caret-down-fill"></i>
          </div>
          <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
            <a *ngIf="totalCustomer > 1" class="dropdown-item" (click)="onClickChangeCustomer()"><i
                class="bi bi-person-lines-fill me-2"></i>Change customer
              account</a>
            <a class="dropdown-item" (click)="logout()"><i class="bi bi-shield-lock me-2"></i>Logout</a>
          </div>
        </div>
        <i class="bi bi-cart-fill" *ngIf="currentUser['name']"></i>
      </div>
    </div>
  </div>
</div>
<div class="logo-wrap text-white py-2">
  <div class="theme-container">
    <div class="row align-items-center">
      <a routerLink="/" class="col-md-3 justify-content-center d-flex justify-content-md-start my-3 cursor-pointer">
        <img width="200" src="assets/images/logo.svg" alt="" />
      </a>
      <div class="col-md-9">
        <span class="fw-light"><strong class="fw-bold">OUR SPECIALITIES </strong>- Simple Design
          Process, Unbeatable Pricing, Highest Quality Guaranteed, Fast & Easy
          Ordering
        </span>
      </div>
    </div>
  </div>
</div>
<div class="user-wrap text-white py-2" *ngIf="currentUser['name'] &&  !isArtworkRequestForm">
  <div class="theme-container">
    <div class="user-profile d-flex align-items-center flex-column flex-md-row">
      <div class="user-pic">
        <img src="/assets/images/account/user.png" />
      </div>
      <div class="user-name mx-3 my-1 my-md-0" title="{{ currentUser['name'].length > 15 ? currentUser['name'] : ''}}">
        {{currentUser['name'].length > 15 ? (currentUser['name'] | slice:0:15 | uppercase ) + '...' :
        currentUser['name'] }}
      </div>
      <div class="user-profile-info mb-1 mb-md-0">
        From your account dashboard you can view your <span>recent orders</span>, manage your
        <span class="cursor-pointer" [routerLink]="['/account/addresses']">shipping</span> and <span
          class="cursor-pointer" [routerLink]="['/account/addresses']">billing</span> addresses and <span
          class="cursor-pointer" (click)="onClickChangePassword()">change password</span> and more
      </div>
    </div>
  </div>
</div>