import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, ControlContainer, Validators, FormArray } from '@angular/forms';

import { isEmpty } from '@salesonepro/utils';

@Component({
  selector: 'app-order-screen-printing-additional-form',
  templateUrl: './additional-form.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
})
export class ScreenPrintingAdditionalFormComponent implements OnChanges {
  @Input() decorationMethod: string;
  @Input() logoDetails;
  @Input() logoThreadDetails;
  @Input() dstInfo;
  @Input() error;
  @Input() invalidForm: boolean;
  @Input() threadColors: any = [];
  @Input() threadTypeArray: any = [];
  @Input() defaultThreadType: number;
  @Input() orderOptions: any;
  @Input() noOfColors: number;
  @Input() loadingDecorationOptions: boolean;
  @Input() decorationOptions: any;

  form: FormGroup;
  selectedThreadColor: any = {};
  threadColorArray: any = [];

  threadDetails = [];


  constructor(
    private parent: FormGroupDirective,
    private fb: FormBuilder,
    private el: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.form) {
      this.form = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
    }
    if (this.form) {
      if (changes.dstInfo && this.dstInfo) {
        this.logoThreadDetails = [];
        const threadDetailsArray = this.form.get(['color_details']) as FormArray;
        threadDetailsArray.controls = [];
        this.dstInfo.color_stich_array.forEach((stitch, index) => {
          let threadTypeId = '';
          const defaultThread = this.threadTypeArray.find(x => x.id === this.defaultThreadType);
          if (defaultThread) {
            threadTypeId = defaultThread.id;
            const threadTypeName = defaultThread.data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[index] = allowedColors;
          } else {
            threadTypeId = this.threadTypeArray[0];
            const threadTypeName = this.threadTypeArray[0].data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[index] = allowedColors;
          }
          const colorId = this.threadColorArray[index][index] ? this.threadColorArray[index][index]['id'] : this.threadColorArray[index][0]['id'];
          const tmp = {
            color_type: {
              id: threadTypeId
            },
            color: {
              id: colorId
            },
            color_description: ''
          };
          this.logoThreadDetails.push(tmp);
          const threadDetailsForm = this.initThreadForm(tmp);
          threadDetailsArray.push(threadDetailsForm);
          const selectedColor = this.getThreadColorDetails(this.threadColorArray[index], colorId);
          if (selectedColor) {
            this.setThreadColor(selectedColor, index);
          }
        });
      }
      if (!this.loadingDecorationOptions && this.decorationMethod && this.logoDetails && this.logoDetails['data'][this.decorationMethod] && this.logoDetails['data'][this.decorationMethod]['color_details']) {
        this.logoThreadDetails = this.logoDetails['data'][this.decorationMethod]['color_details'];
        const threadDetailsArray = this.form.get(['color_details']) as FormArray;
        threadDetailsArray.controls = [];
        this.logoThreadDetails.forEach((element, index) => {
          const threadDetailsForm = this.initThreadForm(element);
          threadDetailsArray.push(threadDetailsForm);
          const allowedColors = this.getThreadColors(index);
          if (allowedColors) {
            const selectedColor = allowedColors.find(x => x.id === element.color.id);
            this.setThreadColor(selectedColor, index);
            this.threadColorArray[index] = allowedColors;
          } else {
            this.setThreadColor({}, index);
          }
        });
      }
      if (changes.noOfColors && this.noOfColors && !this.loadingDecorationOptions) {
        this.onColorChange(this.noOfColors);
      }
    }
  }

  getThreadColorDetails(threadColors, id) {
    return threadColors.find(x => x.id === id);
  }


  onColorChange(value) {
    const prevData = this.threadDetails ? this.threadDetails : [];
    this.threadDetails = [];
    const threadDetailsArray = this.form.get(['color_details']) as FormArray;
    threadDetailsArray.controls = [];
    if (value > 0) {
      for (let i = 0; i < value; i++) {
        const index = i;
        if (!prevData[index]) {
          let threadTypeId = '';
          const defaultThread = this.threadTypeArray.find(x => x.id === this.defaultThreadType);
          if (defaultThread) {
            threadTypeId = defaultThread.id;
            const threadTypeName = defaultThread.data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[index] = allowedColors;
          } else {
            threadTypeId = this.threadTypeArray[0];
            const threadTypeName = this.threadTypeArray[0].data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[index] = allowedColors;
          }
          const tmp = {
            color_type: {
              id: threadTypeId
            },
            color: {
              id: this.threadColorArray[index][0]['id']
            },
            color_description: ''
          };
          this.threadDetails.push(tmp);
          const threadDetailsForm = this.initThreadForm(tmp);
          threadDetailsArray.push(threadDetailsForm);
          if (this.threadColorArray[index] && this.threadColorArray[index][0]) {
            const selectedColor = this.threadColorArray[index][0];
            this.setThreadColor(selectedColor, index);
          }
        } else {
          const threadType = this.threadTypeArray.find(x => parseInt(x.id) === parseInt(prevData[index]['color_type'].id));
          const threadTypeName = threadType.data.value;
          const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
          this.threadColorArray[index] = allowedColors;
          this.threadDetails.push(prevData[index]);
          const threadDetailsFormData = this.initThreadForm(prevData[index]);
          threadDetailsArray.push(threadDetailsFormData);
        }
      }
    }
  }

  initThreadForm(data?) {
    if (data) {
      return this.fb.group({
        'color_description': [data.color_description],
        'color_type': this.fb.group({
          'id': [data.color_type.id, Validators.required],
        }),
        'color': this.fb.group({
          'id': [data.color.id, Validators.required],
        })
      });
    } else {
      return this.fb.group({
        'color_description': [''],
        'color_type': this.fb.group({
          'id': [''],
        }),
        'color': this.fb.group({
          'id': [''],
        })
      });
    }
  }

  setThreadColor(selectedColor, index) {
    if (isEmpty(selectedColor)) {
      this.form.get(['color_details', index, 'color', 'id']).setValue('');
      this.selectedThreadColor[index] = '';
    } else {
      this.form.get(['color_details', index, 'color', 'id']).setValue(selectedColor.id);
      this.selectedThreadColor[index] = selectedColor.data.color_name;
    }
  }

  getThreadColors(index) {
    const threadDetailsFormData = this.form.get(['color_details']) as FormArray;
    const threadTypeId = threadDetailsFormData.get([index, 'color_type', 'id']).value;
    const defaultThread = this.threadTypeArray.find(x => parseInt(x.id) === parseInt(threadTypeId));
    const threadTypeName = defaultThread.data.value;
    const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
    return allowedColors;
  }

  onThreadColorSelect(selectedColor, index) {
    if (selectedColor) {
      if (this.threadDetails[index]) {
        if (this.threadDetails[index]['color'] && this.threadDetails[index]['color']['id']) {
          this.threadDetails[index]['color'].id = selectedColor.id;
        } else {
          this.threadDetails[index]['color'] = {
            id: selectedColor.id
          };
        }
      }
      this.setThreadColor(selectedColor, index);
    }
  }

  threadChange(event, key, index) {
    if (key === 'color_description') {
      this.threadDetails[index][key] = event.target.value;
    }
    if (key === 'color_type') {
      const allowedColors = this.getThreadColors(index);
      this.threadDetails[index]['color_type']['id'] = event.target.value;
      if (this.threadDetails[index]['color'] && this.threadDetails[index]['color']['id']) {
        this.threadDetails[index]['color']['id'] = allowedColors[0].id ? allowedColors[0].id : '';
      } else {
        this.threadDetails[index]['color'] = {
          id: allowedColors[0].id ? allowedColors[0].id : '',
        };
      }

      if (allowedColors) {
        this.threadColorArray[index] = allowedColors;
      } else {
        this.threadColorArray[index] = [];
      }
      if (this.threadColorArray[index] && this.threadColorArray[index][0]) {
        const selectedColor = this.threadColorArray[index][0];
        this.setThreadColor(selectedColor, index);
      } else {
        this.setThreadColor('', index);
      }
    }
  }

}
