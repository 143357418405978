<form [formGroup]="addressForm" [appSpinner]="isLoading">
  <div class="custom-form">
    <div class="row">
      <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-person-circle"></i></span>
          <div class="form-floating">
            <input type="text" formControlName="name" class="form-control" name="fname" value=""
              placeholder="First Name*" autofocus>
            <label for="fname">Name*</label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('name')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="name"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-envelope-fill"></i></span>
          <div class="form-floating">
            <input type="email" formControlName="email" class="form-control" name="email" value="" placeholder="Email">
            <label for="cname">Email</label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('email')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="email"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-bank"></i></span>
          <div class="form-floating">
            <input type="text" formControlName="company_name" class="form-control" name="cname" value=""
              placeholder="Company Name">
            <label for="cname">Company Name</label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('company_name')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="company_name"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-house-fill"></i></span>
          <div class="form-floating">
            <input type="text" class="form-control" name="staddress1" GooglePlaces
              (addressSelect)="fillInAddress($event)" formControlName="address_1" placeholder="Street Address*">
            <label for="staddress1">Street Address*</label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('address_1')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="address_1"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-house-fill"></i></span>
          <div class="form-floating">
            <input type="text" class="form-control" name="staddress2" formControlName="address_2"
              placeholder="Street Address 2">
            <label for="staddress2">Street Address 2 </label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('address_2')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="address_2"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-buildings-fill"></i></span>
          <div class="form-floating">
            <input type="text" formControlName="city" class="form-control" name="city" value="" placeholder="City*">
            <label for="city">City* </label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('city')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="city"></app-server-validation-error>
        </div>
      </div>

      <!-- <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-geo-alt-fill"></i></span>
          <div class="form-floating">
            <select id="state" (change)="onChangeCountry($event.target.value)" formControlName="country"
              class="form-select border-0">
              <option value="">Select</option>
              <option *ngFor="let item of countryList" [value]="item.code">{{item.name}}
              </option>
            </select>
            <label for="state">Country* </label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('country')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="country"></app-server-validation-error>
        </div>
      </div> -->

      <div class="col-lg-6" *ngIf="addressForm.controls['country'].value === 'US'">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-geo-alt-fill"></i></span>
          <div class="form-floating">
            <select formControlName="state" class="form-select border-0" aria-label="Choose State">
              <option value="">Select</option>
              <option *ngFor="let item of stateList" [value]="item.code">{{item.name}}
              </option>
            </select>
            <label for="state">State* </label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('state')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="state"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6" *ngIf="addressForm.controls['country'].value !== 'US'">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-geo-alt-fill"></i></span>
          <div class="form-floating">
            <input type="text" formControlName="state_text" class="form-control" name="state" value=""
              placeholder="State*">
            <label for="state">State* </label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('state_text')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="state_text"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-envelope-fill"></i></span>
          <div class="form-floating">
            <input type="text" formControlName="zip" class="form-control" name="zipcode" value=""
              placeholder="Zip Code*">
            <label for="zipcode">Zip Code* </label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('zip')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="zip"></app-server-validation-error>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="input-group form-custom mb-3">
          <span class="input-group-text"><i class="bi bi-telephone-fill"></i></span>
          <div class="form-floating">
            <input type="text" formControlName="phone" class="form-control" name="phonenumber" [textMask]="{mask: mask}"
              placeholder="Phone Number*">
            <label for="phonenumber">Phone Number*</label>
          </div>
        </div>
        <div class="mb-2">
          <app-validation-errors [submitted]="invalidAdrsForm"
            [control]="addressForm.get('phone')"></app-validation-errors>
          <app-server-validation-error [errors]="errors" key="phone"></app-server-validation-error>
        </div>
      </div>

    </div>
  </div>
</form>