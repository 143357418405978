import { Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';

// R = Response Data Type,
export class ConfirmationOverlayRef<R = any> {
  afterClosed$ = new Subject();

  constructor(
    public overlay: OverlayRef,
    public content: any,
  ) {
    // overlay.backdropClick().subscribe(() => {
    //   this._close('backdropClick', null);
    // });
  }

  close(data?: R) {
    this._close(data);
  }

  private _close(data: R) {
    this.overlay.dispose();
    this.afterClosed$.next(data);
    this.afterClosed$.complete();
  }
}