import { Component } from '@angular/core';

import { FlatEmbroideryFormComponent } from '../../flat_embroidery/form/form.component';

@Component({
  selector: 'app-order-text-embroidery-form',
  templateUrl: './form.component.html'
})
export class TextEmbroideryFormComponent extends FlatEmbroideryFormComponent {
  override decorationType = 'text_embroidery';
}
