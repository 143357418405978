import { Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, ControlContainer, Validators, FormArray } from '@angular/forms';

import { isEmpty } from '@salesonepro/utils';

@Component({
  selector: 'app-order-text-embroidery-additional-form',
  templateUrl: './additional-form.component.html',
  viewProviders: [{
    provide: ControlContainer,
    useExisting: FormGroupDirective
  }],
})
export class TextEmbroideryAdditionalFormComponent implements OnChanges {
  @Input() decorationMethod: string;
  @Input() logoDetails;
  @Input() logoThreadDetails;
  @Input() dstInfo;
  @Input() error;
  @Input() invalidForm: boolean;
  @Input() threadColors: any = [];
  @Input() threadTypeArray: any = {};
  @Input() defaultThreadType: number;
  @Input() orderOptions: any;
  @Input() loadingDecorationOptions: boolean;
  @Input() decorationOptions: any;

  form: FormGroup;
  selectedThreadColor = {};
  threadColorArray: any = {};

  threadDetails = {};

  lineTextList = [
    {id: 'line1', 'label': 'First Line Text', value: 1},
  ];

  pendingLineTextList = [
    {id: 'line2', 'label': 'Second Line Text', value: 2},
    {id: 'line3', 'label': 'Third Line Text', value: 3},
    {id: 'line4', 'label': 'Fourth Line Text', value: 4},
  ];

  fullTextList = [
    {id: 'line1', 'label': 'First Line Text', value: 1},
    {id: 'line2', 'label': 'Second Line Text', value: 2},
    {id: 'line3', 'label': 'Third Line Text', value: 3},
    {id: 'line4', 'label': 'Fourth Line Text', value: 4},
  ];

  constructor(
    private parent: FormGroupDirective,
    private fb: FormBuilder,
    private el: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.decorationMethod && this.decorationMethod && this.form) {
      this.form = null;
    }
    if (changes.decorationOptions && this.decorationOptions) {

      const data = this.logoDetails && this.logoDetails['data'][this.decorationMethod] ? this.logoDetails['data'][this.decorationMethod] : null;
      if (!this.form) {
        this.form = this.parent.form.get(['data', this.decorationMethod]) as FormGroup;
        if (data) {
          this.fullTextList.forEach(element => {
            if (data[element.id]) {
              this.threadDetails[element.id] = [];
              this.selectedThreadColor[element.id] = [];
              this.threadColorArray[element.id] = [];
              this.lineTextList.push(element);
              this.form.addControl(element.id, this.initLineForm(data[element.id]));
              if (data[element.id].thread_details.length > 0) {
                this.threadDetails[element.id] = data[element.id].thread_details;
                this.onColorChange(data[element.id].no_of_colors, element.id);
              }
            }
          });
        } else {
          this.lineTextList.forEach(element => {
            this.threadDetails[element.id] = [];
            this.selectedThreadColor[element.id] = [];
            this.threadColorArray[element.id] = [];
            this.form.addControl(element.id, this.initLineForm());
          });
        }

      }
    }
  }

  initLineForm(data?) {
    const formGrp =  this.fb.group({
      case_style: [data && data.case_style ? data.case_style : '', [Validators.required]],
      lettering_size: [data && data.lettering_size ? data.lettering_size.id :'', [Validators.required]],
      lettering_style: [data && data.lettering_style ? data.lettering_style.id :'', [Validators.required]],
      no_of_colors: [data && data.no_of_colors ? data.no_of_colors :'', [Validators.required, Validators.min(1)]],
      note: [data && data.note ? data.note :''],
      text: [data && data.text ? data.text :'', [Validators.required]],
      thread_details: this.fb.array([])
    });
    return formGrp;
  }


  onColorChange(value, type) {
    const prevData = this.threadDetails[type] ? this.threadDetails[type] : [];
    this.threadDetails[type] = [];
    const threadDetailsArray = this.form.get([type, 'thread_details']) as FormArray;
    threadDetailsArray.controls = [];
    if (value > 0) {
      for (let i = 0; i < value; i++) {
        const index = i;
        if (!prevData[index]) {
          let threadTypeId = '';
          const defaultThread = this.threadTypeArray.find(x => x.id === this.defaultThreadType);
          if (defaultThread) {
            threadTypeId = defaultThread.id;
            const threadTypeName = defaultThread.data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[type][index] = allowedColors;
          } else {
            threadTypeId = this.threadTypeArray[0];
            const threadTypeName = this.threadTypeArray[0].data.value;
            const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
            this.threadColorArray[type][index] = allowedColors;
          }
          const tmp = {
            stitch_count: 0,
            color_type: {
              id: threadTypeId
            },
            color: {
              id: this.threadColorArray[type][index][0]['id']
            },
            color_description: ''
          };
          this.threadDetails[type].push(tmp);
          const threadDetailsForm = this.initThreadForm(tmp);
          threadDetailsArray.push(threadDetailsForm);
          if (this.threadColorArray[type][index] && this.threadColorArray[type][index][0]) {
            const selectedColor = this.threadColorArray[type][index][0];
            this.setThreadColor(selectedColor, index, type);
          }
        } else {
          const threadType = this.threadTypeArray.find(x => parseInt(x.id) === parseInt(prevData[index]['color_type'].id));
          const threadTypeName = threadType.data.value;
          const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
          this.threadColorArray[type][index] = allowedColors;
          this.threadDetails[type].push(prevData[index]);
          const threadDetailsFormData = this.initThreadForm(prevData[index]);
          threadDetailsArray.push(threadDetailsFormData);
        }
      }
    }
  }

  initThreadForm(data?) {
    if (data) {
      return this.fb.group({
        'stitch_count': [data.stitch_count],
        'color_description': [data.color_description],
        'color_type': this.fb.group({
          'id': [data.color_type.id, Validators.required],
        }),
        'color': this.fb.group({
          'id': [data.color.id, Validators.required],
        })
      });
    } else {
      return this.fb.group({
        'stitch_count': [''],
        'color_description': [''],
        'color_type': this.fb.group({
          'id': [''],
        }),
        'color': this.fb.group({
          'id': [''],
        })
      });
    }
  }

  setThreadColor(selectedColor, index, type) {
    if (isEmpty(selectedColor)) {
      this.form.get([type, 'thread_details', index, 'color', 'id']).setValue('');
      this.selectedThreadColor[type][index] = '';
    } else {
      this.form.get([type, 'thread_details', index, 'color', 'id']).setValue(selectedColor.id);
      this.selectedThreadColor[type][index] = selectedColor.data.color_name;
    }
  }

  getThreadColors(index, type) {
    const threadDetailsFormData = this.form.get([type, 'thread_details']) as FormArray;
    const threadTypeId = threadDetailsFormData.get([index, 'color_type', 'id']).value;
    const defaultThread = this.threadTypeArray.find(x => parseInt(x.id) === parseInt(threadTypeId));
    const threadTypeName = defaultThread.data.value;
    const allowedColors = this.threadColors.filter(x => x.data.color_type === threadTypeName);
    return allowedColors;
  }

  onThreadColorSelect(selectedColor, index, type) {
    if (selectedColor) {
      if (this.threadDetails[type] &&  this.threadDetails[type][index]) {
        if (this.threadDetails[type][index]['color'] && this.threadDetails[type][index]['color']['id']) {
          this.threadDetails[type][index]['color'].id = selectedColor.id;
        } else {
          this.threadDetails[type][index]['color'] = {
            id: selectedColor.id
          };
        }
      }
      this.setThreadColor(selectedColor, index, type);
    }
  }

  threadChange(event, key, index, type) {
    if (key === 'color_description') {
      this.threadDetails[type][index][key] = event.target.value;
    }
    if (key === 'color_type') {
      const allowedColors = this.getThreadColors(index, type);
      this.threadDetails[type][index]['color_type']['id'] = event.target.value;
      if (this.threadDetails[type][index]['color'] && this.threadDetails[type][index]['color']['id']) {
        this.threadDetails[type][index]['color']['id'] = allowedColors[0].id ? allowedColors[0].id : '';
      } else {
        this.threadDetails[type][index]['color'] = {
          id: allowedColors[0].id ? allowedColors[0].id : '',
        };
      }

      if (allowedColors) {
        this.threadColorArray[type][index] = allowedColors;
      } else {
        this.threadColorArray[type][index] = [];
      }
      if (this.threadColorArray[type][index] && this.threadColorArray[type][index][0]) {
        const selectedColor = this.threadColorArray[type][index][0];
        this.setThreadColor(selectedColor, index, type);
      } else {
        this.setThreadColor('', index, type);
      }
    }
  }


  addLineText() {
    if (this.lineTextList.length < 4) {
      const element = this.pendingLineTextList.find(x => x.value === (this.lineTextList.length + 1));
      this.form.addControl(element.id, this.initLineForm());
      this.lineTextList.push(element);
      this.threadDetails[element.id] = [];
      this.selectedThreadColor[element.id] = [];
      this.threadColorArray[element.id] = [];
      const target = this.el.nativeElement.querySelector('#' + element.id);
      if (target) {
        target.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }
  }

  removeLineText(element) {
    this.form.removeControl(element.id);
    const index = this.lineTextList.findIndex(x => x.id === element.id);
    if (index > -1) {
      this.lineTextList.splice(index, 1);
    }
  }

}
