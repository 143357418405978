import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-address-block',
  templateUrl: 'address-block.component.html',
  styleUrls: ['./address-block.component.scss']
})

export class AddressBlockComponent {

  @Input() addressData: any;
}