<div class="modal-dialog modal-dialog-scrollable">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-transparent btn-sm float-end px-2 me-2" (click)="decline()">
        {{ btnCancelText }}
      </button>
      <button type="button" class="btn btn-primary text-white btn-sm px-2" (click)="accept()">{{ btnOkText }}</button>
    </div>
  </div>
</div>