import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-text-embroidery-listing',
  templateUrl: './listing.component.html',
})
export class TextEmbroideryListingComponent {
  @Input() artworkDetails;

  lineTextList = [
    {id: 'line1', 'label': 'First Line Text'},
    {id: 'line2', 'label': 'Second Line Text'},
    {id: 'line3', 'label': 'Third Line Text'},
    {id: 'line4', 'label': 'Fourth Line Text'},
  ];

  caseStyleLabel = {
    upper: 'Uppercase',
    upper_lower: 'Uppercase/Lowercase'
  };
}
