import { Injectable } from '@angular/core';
import { AuthService } from '@salesonepro/services/auth.service';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard {

  constructor(private authService: AuthService) { }

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated.pipe(take(1), map(bool => !bool));
}
}
