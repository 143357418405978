import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';

import { ModalPopupOverlayRef } from '@salesonepro/components';
@Component({
  selector: 'app-text-insert-form',
  templateUrl: 'text-insert-form.component.html'
})

export class TextInsertFormComponent implements OnInit {

  form: FormGroup;
  decorationOptions: any;
  colorOptions = [];

  invalidForm: boolean;
  isFontTypeOther: boolean;

  constructor(
    private fb: FormBuilder,
    private element: ElementRef,
    @Inject(ModalPopupOverlayRef) public modalRef: any,
  ) {
    this.decorationOptions = modalRef.data.input['decorationOptions'];
  }

  ngOnInit() {
    this.form = this.fb.group({
      'insert_font_type': [null, Validators.required],
      'insert_placement': ['', Validators.required],
      'insert_font_type_other': [''],
      'lines': this.fb.array([new FormControl('', Validators.required)]),
      'color_type': ['', Validators.required],
      'color': ['', Validators.required],
      'qty': [1, Validators.required],
    });
    if (this.modalRef.data.input['decorationFormGroup']['insert_text_thread_details']) {
      const data = this.modalRef.data.input['decorationFormGroup']['insert_text_thread_details'][0];
      this.form.patchValue(data);
      this.lineFormArray.controls.length = 0;
      data.lines.forEach(line => {
        this.lineFormArray.push(new FormControl(line));
      });

      this.onColorTypeChange();
      this.onFontTypeChange();
    }
  }

  groupByFn = (item) => item.data.group;
  get lineFormArray() { return this.form.get('lines') as FormArray;}

  addLineText() {
    const linesFormArray = this.form.get('lines') as FormArray;
    const lineCtrl = new FormControl('', Validators.required);
    linesFormArray.push(lineCtrl);
  }

  removeLineText(index) {
    const linesFormArray = this.form.get('lines') as FormArray;
    linesFormArray.removeAt(index);
  }

  onColorTypeChange() {
    const value = this.form.get('color_type').value;
    const option = this.decorationOptions['color_type'].find(x => parseInt(x.id) === parseInt(value));
    if (option) {
      this.colorOptions = this.decorationOptions['color'].filter(x => x.data.color_type === option.data.value);
    }
  }

  onFontTypeChange() {
    const fontTypeId = this.form.get('insert_font_type').value;
    const fontType = this.decorationOptions['insert_font_type'].find(x => x.id === fontTypeId);
    if (fontType && fontType.data && fontType.data.name === 'OTHER') {
      this.isFontTypeOther = true;
      this.form.get('insert_font_type_other').setValidators([Validators.required]);
    } else {
      this.isFontTypeOther = false;
      this.form.get('insert_font_type_other').clearValidators();
    }
    this.form.get('insert_font_type_other').updateValueAndValidity();
  }

  onSubmit() {
    this.invalidForm = true;
    if (this.form.valid) {
      this.invalidForm = false;
      const data = {
        insert_text_thread_details: [this.form.value]
      };
      this.modalRef.close(data);
    } else {
      const target = this.element.nativeElement.querySelector('.ng-invalid:not(form)');
      if (target) {
        target.focus();
      }
    }
  }
}