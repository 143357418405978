import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';


// thirdparty modules
import { TranslateModule } from '@ngx-translate/core';
import { TextMaskModule } from 'ngx-textmask';

import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';

export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

// modules
import { SpinnerModule } from './modules/spinner/spinner.module';
import { TableModule } from './modules/table/table.module';
import { AutocompleteModule } from './modules/autocomplete/autocomplete.module';

// components
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';
import { IconComponent } from './components/icon/icon.component';
import { ValidationErrorsComponent } from './components/validation-error/validation-error.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AudioControllerComponent } from './components/audio-controller/audio-controller.component';
import { ACCORDION_DIRECTIVES } from './components/accordion/export';
import { ServerValidationErrorComponent } from './components/server-validation-error/server-validation-error.component';
import { RecaptchaFormComponent } from './components/recaptcha-form/recaptcha-form.component';

// pipes
import { CurrencyFormat } from './pipes/currency-format.pipe';
import { FormatTimestamp } from './pipes/format-timestamp.pipe';
import { FormatTimestampedDatePipe } from './pipes/format-timestamped-date.pipe';
import { FormatPhoneNumberPipe } from './pipes/format-phone.pipe';
import { AbsoluteValue } from './pipes/absolute-value.pipe';
import { RemoveUnderscorePipe } from './pipes/remove-underscore.pipe';
import { AddBreakPipe } from './pipes/add-break.pipe';

// directives
import { AsideToggleDirective } from './directives/aside.directive';
import { ClickOutsideDirective } from './directives/dropdown.directive';
import { IsGrantedDirective } from './security/is-granted.directive';
import { NoScrollInputDirective } from './directives/no-scroll-input.directive';
import { GooglePlacesDirective } from './directives/google-places.directive';

import { AuthInterceptor } from './interceptor';
import { TrimWhitespaceDirective } from './directives/trim-whitespace.directive';

import { TabsetComponent, TabDirective, TabContentDirective, TabTitleDirective} from './components/tabset';
const TABSET_DIRECTIVES = [TabsetComponent, TabDirective, TabContentDirective, TabTitleDirective];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    SpinnerModule,
    TableModule,
    AutocompleteModule,
    OverlayModule,
    // thirdparty modules
    TranslateModule,
    TextMaskModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  declarations: [
    // components
    IconComponent,
    ModalPopupComponent,
    ValidationErrorsComponent,
    ACCORDION_DIRECTIVES,
    LoaderComponent,
    SpinnerComponent,
    ConfirmationDialogComponent,
    AudioControllerComponent,
    ServerValidationErrorComponent,
    RecaptchaFormComponent,

    // directives
    IsGrantedDirective,
    AsideToggleDirective,
    ClickOutsideDirective,
    NoScrollInputDirective,
    TrimWhitespaceDirective,
    TABSET_DIRECTIVES,
    GooglePlacesDirective,

    // pipes
    CurrencyFormat,
    FormatTimestamp,
    FormatPhoneNumberPipe,
    AbsoluteValue,
    FormatTimestampedDatePipe,
    RemoveUnderscorePipe,
    AddBreakPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    // modules
    SpinnerModule,
    TableModule,
    AutocompleteModule,
    RecaptchaModule,
    RecaptchaFormsModule,

    // thirdparty modules
    TranslateModule,
    TextMaskModule,
    OwlDateTimeModule,

    // components
    IconComponent,
    ModalPopupComponent,
    ValidationErrorsComponent,
    LoaderComponent,
    SpinnerComponent,
    ConfirmationDialogComponent,
    AudioControllerComponent,
    ServerValidationErrorComponent,
    RecaptchaFormComponent,

    // directives
    IsGrantedDirective,
    AsideToggleDirective,
    ClickOutsideDirective,
    NoScrollInputDirective,
    TrimWhitespaceDirective,
    ACCORDION_DIRECTIVES,
    TABSET_DIRECTIVES,
    GooglePlacesDirective,

    // pipes
    CurrencyFormat,
    FormatTimestamp,
    FormatPhoneNumberPipe,
    AbsoluteValue,
    FormatTimestampedDatePipe,
    RemoveUnderscorePipe,
    AddBreakPipe,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: MY_NATIVE_FORMATS
    },
  ]
})
export class SalesOneProCommonModule {}
