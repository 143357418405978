import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

import { DecorationTypeFormComponent } from '../../form.component';

@Component({
  selector: 'app-order-sublimation-form',
  templateUrl: './form.component.html'
})
export class SublimationFormComponent extends DecorationTypeFormComponent {

  override decorationType = 'sublimation';

}
