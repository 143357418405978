/* eslint-disable no-useless-escape */
import { FormControl, FormGroup, AbstractControl,  ValidatorFn } from '@angular/forms';

const luhnChk = (arr => {
  return ccNum => {
    let len = ccNum.length,
      bit = 1,
      sum = 0,
      val;

    while (len) {
      val = parseInt(ccNum.charAt(--len), 10);
      // eslint-disable-next-line no-cond-assign
      sum += (bit ^= 1) ? arr[val] : val;
    }

    return sum && sum % 10 === 0;
  };
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);

export class CustomValidator {

  static email(control: FormControl) {

    // tslint:disable-next-line:max-line-length
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return EMAIL_REGEXP.test(control.value) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }

  static noWhitespace(control: FormControl) {
    const pattern = /^[^\s]+(\s+[^\s]+)*$/;
    return (pattern.test(control.value)) ? null : {
      noWhitespace: {
        valid: false
      }
    };
  }

  static number(control: FormControl) {

    const pattern = /^[0-9]+(\.[0-9]*){0,1}$/g;

    return pattern.test(control.value) ? null : {
      validateNumber: {
        valid: false
      }
    };
  }

  static password(control: FormControl) {

    const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{5,20}/;
    return pattern.test(control.value) ? null : {
      invalidPassword: {
        valid: false
      }
    };
  }


  static CCValidator(c: FormControl) {
    return c.value && luhnChk(c.value.toString())
      ? null
      : { invalidCreditCard: true };
  }

  static pricingField(control: FormControl) {

    const pattern = /^[0-9]+(\.[0-9]*){0,1}$/g;

    return (pattern.test(control.value) || control.value === '+') ? null : {
      validatePricingField: {
        valid: false
      }
    };
  }

  static confirmPassword(formGP: FormGroup) {
    const password = formGP.controls.new_password.value;
    const repeatPassword = formGP.controls.confirm_password.value;

    if (repeatPassword.length <= 0) {
      return null;
    }

    if (repeatPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }

    return null;
  }

  static url(control: FormControl) {

    const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;

    return pattern.test(control.value) ? null : {
      url: {
        valid: false
      }
    };
  }


  static checkDateValidator(expiredMonth: string, expiredYear: string) {
    return (group: FormGroup) => {
      const month = group.controls[expiredMonth];
      const year = group.controls[expiredYear];
      const lastDay = new Date(year.value, month.value, 0);
      const expiryDate = new Date(
        year.value + '-' + month.value + '-' + lastDay
      );
      if (year && year.value) {
        expiryDate < new Date()
          ? year.setErrors({ invalidDate: true })
          : year.setErrors(null);
      }
    };
  }

}
