import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AccountService } from '@core/account/account.service';
@Component({
  selector: 'app-address-change-block',
  templateUrl: './address-change-block.component.html',
  styleUrls: ['./address-change-block.component.scss']
})
export class AddressChangeBlockComponent implements OnInit {
  @Input() selectedAddress: any;
  @Input() selectedAddressId: string;
  @Input() selectedAddressType: string;
  @Input() isAddressUpdating: boolean;
  @Input() formSubmitted: boolean;
  @Input() parentType = 'normal';
  @Input() formGroup: FormGroup;
  @Input() invalidFormData: boolean;
  @Input() customerId: string;

  errors: any;
  addressList = {
    count: 0,
    results: [],
    next: null,
    previous: null
  };
  showAddressForm: boolean;

  clearSearch;

  partialLoading: boolean;
  searchLoading: boolean;
  @Output() addressSelect = new EventEmitter();
  @Output() newAddressSave = new EventEmitter();

  isAddressLoading: boolean;
  searchFormControl = new FormControl();
  params = new HttpParams();
  limit = 10;
  page = 1;

  constructor(
    private accountService: AccountService
  ) {
    this.searchFormControl.valueChanges
    .pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(term => {
      this.onSearch(term);
    });
  }

  ngOnInit(): void {
    this.getAddress();
  }


  getAddress() {
    this.params = this.params.delete('search');
    this.params = this.params.delete('offset');
    this.page = 1;
    this.fetchAddresses();
  }

  fetchAddresses(type?) {
    if (type) {
      if (type === 'pagination') {
        this.partialLoading = true;
      } else if (type === 'search') {
        this.searchLoading = true;
      }
    } else {
      this.isAddressLoading = true;
    }
    this.accountService.getCustomerAddress(this.params).subscribe(response => {
      if (response.count === 0) {
        this.showAddressForm = true;
      } else {
        this.showAddressForm = false;
      }
      this.addressList = response;
      this.isAddressLoading = false;
      this.searchLoading = false;
      this.partialLoading = false;
    });
  }

  onPaginate(type) {
    let offset = 0;
    let url = null;
    if (type === 'next' && this.addressList.next) {
      this.page += 1;
      url = this.addressList.next;
    } else if (type === 'prev' && this.addressList.previous) {
      this.page -= 1;
      url = this.addressList.previous;
    }
    if (url && url !== '') {
      offset = (this.page - 1) * this.limit;
      this.params = this.params.delete('offset');
      this.params = this.params.append('offset', offset.toString());
      this.fetchAddresses('pagination');
    }
  }

  onSearch(searchTerm) {
    this.params = this.params.delete('search');
    this.params = this.params.delete('offset');
    this.page = 1;
    if (searchTerm !== '') {
      this.params = this.params.append('search', searchTerm);
      this.fetchAddresses('search');
    } else {
      this.fetchAddresses('search');
    }
  }

  addNewAddress() {
    this.selectedAddress = null;
    this.showAddressForm = true;
    this.errors = null;
    this.selectedAddress = null;
    if (this.formGroup) {
      this.formGroup.reset();
      this.formGroup.get('state').setValue('');
    }
  }

  editBillingAddress(item) {
    this.showAddressForm = true;
    this.selectedAddress = item;
    this.errors = null;
  }

  onSelectAddress(address) {
    this.addressSelect.emit(address);
  }

  emitRadioChange(e) {
    if (this.parentType === 'chase') {
      const findAddr = this.addressList['results'].find(x => x.id === e.target.value);
      if (findAddr) {
        this.addressSelect.emit(findAddr);
      }
    }
  }
}
