
import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';

import { StorageService } from '../services/storage.service';
/**
 * Access checker service.
 *
 * Injects `RoleProvider` to determine current user access permissions
 */
@Injectable({
  providedIn: 'root'
})
export class AccessChecker {

  constructor(
    private storage: StorageService,
  ) {}

  /**
   * Checks whether access is granted or not
   *
   */
  isGranted(role: string): Observable<boolean> {
    if (!role) {
      throw new Error('Role name cannot be empty');
    }
    if (this.storage.retrieve('acl', 'session')) {
      const permission = this.storage.retrieve('acl', 'session');
      return permission.includes(role);
    }

    return observableOf(false);
  }
}
