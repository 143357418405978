import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '@salesonepro/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private inj: Injector,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const storage = this.inj.get(StorageService);
    const customer = storage.retrieve('selected_customer');
    if (customer && customer.id) {
      request = request.clone({ headers: request.headers.set('Customer', customer.id) });
    }
    return next.handle(request);
  }
}
