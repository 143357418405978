import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-server-validation-error',
  templateUrl: './server-validation-error.component.html',
})
export class ServerValidationErrorComponent {
  @Input() errors: any;
  @Input() key: string | number;

  isArray(obj) {
    return Array.isArray(obj);
  }

}
