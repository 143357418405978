import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef, Type } from '@angular/core';

import { ModalPopupComponent } from './modal-popup.component';
import { ModalPopupOverlayRef, ModalConfig } from './modal-popup-ref';

@Injectable({
  providedIn: 'root'
})
export class ModalPopupService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  open<R = any, T = any>(content: string | TemplateRef<any> | Type<any>, data: ModalConfig): ModalPopupOverlayRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: !data.hideBackdrop ? true: false,
      panelClass: ['modal', 'd-block', data.panelClass],
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        autoClose: false
      }),
    });

    const overlayRef = this.overlay.create(configs);
    const myOverlayRef = new ModalPopupOverlayRef<R, T>(overlayRef, content, data);

    const injector = this.createInjector(myOverlayRef, this.injector);
    overlayRef.attach(new ComponentPortal(ModalPopupComponent, null, injector));

    return myOverlayRef;
  }

  createInjector(ref: ModalPopupOverlayRef, inj: Injector) {
    return Injector.create({
      parent: inj,
      providers: [
        { provide: ModalPopupOverlayRef, useValue: ref }
      ]
    });
  }
}