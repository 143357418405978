import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(
    private storage: LocalStorageService,
    private sessionStorage: SessionStorageService,
  ) { }


  store(itemName, data, type = 'local') {
    if (type === 'local') {
      this.storage.store(itemName, data);
    } else {
      this.sessionStorage.store(itemName, data);
    }
  }

  retrieve(itemName, type = 'local') {
    if (type === 'local') {
      return this.storage.retrieve(itemName);
    } else {
      return this.sessionStorage.retrieve(itemName);
    }
  }

  observe(raw: string, type = 'local') {
    if (type === 'local') {
      return this.storage.observe(raw);
    } else {
      return this.sessionStorage.observe(raw);
    }
  }

  clearAll() {
    this.storage.clear();
    this.sessionStorage.clear();
  }

  clear(raw: string) {
    this.storage.clear(raw);
    this.sessionStorage.clear(raw);
  }

  enp() {
    return 'd4BSHfN2mYnvKBp/rhwFsX7KpWTlaPZSMNW9icFMk14=';
  }
}
