import { Directive, HostListener } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[type="number"]',
  })
  export class NoScrollInputDirective {
    @HostListener('wheel', ['$event'])
    onWheel(event: Event) {
      event.preventDefault();
    }

  }
