import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultRouteGuard  {
  constructor(
    private router: Router,
    private storage: StorageService,
  ) {}

  canActivate(): boolean {
    if (this.storage.retrieve('defaultRoute')) {
      const defaultRoute = this.storage.retrieve('defaultRoute');
      this.router.navigate([defaultRoute]);
    } else {
      this.router.navigate(['./dashboard']);
      return true;
    }
  }

}
