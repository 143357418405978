<div class="p-2 border mt-2">
  <div class="row">
    <div class="col-md-4">
      <p class="mb-1" *ngFor="let text of item.lines">{{text}}</p>
    </div>
    <div class="col-md-4">
      <p class="mb-1" *ngIf="item.insert_font_type.data.name !== 'OTHER'">
        Font Type: <b>{{item.insert_font_type.data.name}}</b>
      </p>
      <p class="mb-1" *ngIf="item.insert_font_type.data.name === 'OTHER'">
        Font Type: <b>{{item.insert_font_type_other}}</b>
      </p>
      <p class="mb-0">
        Placement: <b>{{item.insert_placement.data.value}}</b>
      </p>
    </div>
    <div class="col-md-4">
      <p class="mb-1">
        Color Type: <b>{{item.color_type.data.value}}</b>
      </p>
      <div class="mb-0 d-block">
        <div class="float-start me-2">Color: </div>
        <div class="float-start" *ngIf="item.color">
          <div class="border p-2 me-2 float-start" [style.backgroundColor]="item.color.data.hex_code">
          </div>
          <div class="float-start"><b>{{item.color.data.color_code}} - {{item.color.data.color_name}}</b></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="editMode">
    <div class="col-md-3">
      <a class="cursor-pointer" (click)="onIsInsertClick(true)">Edit</a>
      <a class="cursor-pointer ms-2" (click)="removeInsertData()">Remove</a>
    </div>
  </div>
</div>