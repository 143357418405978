import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

// components
import { ChangePswdDialogComponent } from '@core/account/information/change-pswd-dialog/change-pswd-dialog.component';
import { CustomerPopupComponent } from '@core/auth/customer-popup/customer-popup.component';

// services
import { AuthService, StorageService } from '@salesonepro/services';

// others
import { ModalPopupService, User } from '@salesonepro/index';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  sub: any;
  routerData: any;
  totalCustomer: any;
  userAcronym: any;
  isArtworkRequestForm: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private ModalPopupService: ModalPopupService,
    private storage: StorageService,
    private modalService: ModalPopupService
  ) { }

  ngOnInit(): void {
    this.routerData = this.route.snapshot.firstChild;
    this.getCurrentUserInfo();
    if (this.storage.retrieve('customers', 'session')) this.totalCustomer = this.storage.retrieve('customers', 'session').length;
    this.checkRoute();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkRoute();
    });
  }

  checkRoute() {
    const currentRoute = this.router.url;
    this.isArtworkRequestForm = currentRoute.includes('artwork-request-form');
  }

  getCurrentUserInfo() {
    this.authService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        if (userData && userData['name']) {
          const matches = userData['name'].match(/\b(\w)/g);
          this.userAcronym = matches.join('');
        }
      }
    );
  }

  logout() {
    this.authService.purgeAuth();
    this.router.navigate(['/', 'login']);
  }

  onClickChangeCustomer() {
    this.getPopUpWindow();
  }

  getPopUpWindow() {
    const customer = this.storage.retrieve('customers', 'session');
    this.ModalPopupService.open(CustomerPopupComponent, {
      'title': 'CHANGE CUSTOMER ACCOUNT',
      'hideBackdrop': false,
      'size': 'lg',
      'position': 'top',
      'disableBackdropClick': true,
      'headerClass': 'bg-primary text-white',
      'input': { customer: customer, refresh: true },
      'hideCloseButton': false
    });
  }

  onClickChangePassword() {
    this.modalService.open(ChangePswdDialogComponent, {
      'title': 'CHANGE PASSWORD',
      'hideBackdrop': false,
      'size': 'md',
      'position': 'top',
      'disableBackdropClick': true,
      'headerClass': 'bg-primary text-white'
    });
  }

}
