<ul class="logo-nav row">
  <li class="col">
    <div class="h6 mb-1 font-weight-bold">Logo Size (Width)</div>
    <div class="text-muted font-xs">
      {{artworkDetails.logo_width ? artworkDetails.logo_width : 'Proportional to height'}}
    </div>
  </li>
  <li class="col">
    <div class="h6 mb-1 font-weight-bold">Logo Size (Height)</div>
    <div class="text-muted font-xs">
      {{artworkDetails.logo_size ? artworkDetails.logo_size : 'Proportional to width' }}
    </div>
  </li>
  <li class="col">
    <div class="h6 mb-1 font-weight-bold"># of Colors</div>
    <div class="text-muted font-xs" *ngIf="artworkDetails.no_of_colors">{{artworkDetails.no_of_colors}}</div>
  </li>
  <li class="col">
    <div class="h6 mb-1 font-weight-bold">Total Stitch Count</div>
    <div class="text-muted font-xs" *ngIf="artworkDetails.total_stitch_count">{{artworkDetails.total_stitch_count}}
    </div>
  </li>
  <li class="col" *ngIf="artworkDetails.use_digitized_data">
    <div class="h6 mb-1 font-weight-bold">Use Stitch Count From Digitized Logo</div>
    <div class="text-muted font-xs">
      <lib-salesonepro-icon name="check" class="text-success"></lib-salesonepro-icon>
    </div>
  </li>
</ul>
<ul class="logo-nav row"
  *ngIf="artworkDetails.is_digitizing_required || artworkDetails.is_metalic_thread || artworkDetails.is_neon_thread || artworkDetails.is_tape_edit">
  <li class="col" *ngIf="artworkDetails.is_digitizing_required">
    <div class="h6 mb-1 font-weight-bold">Digitizing Required</div>
    <div class="text-muted font-xs">
      <lib-salesonepro-icon name="check" class="text-success"></lib-salesonepro-icon>
    </div>
  </li>
  <li class="col" *ngIf="artworkDetails.is_metalic_thread">
    <div class="h6 mb-1 font-weight-bold">Metallic Thread</div>
    <div class="text-muted font-xs">
      <lib-salesonepro-icon name="check" class="text-success"></lib-salesonepro-icon>
    </div>
  </li>
  <li class="col" *ngIf="artworkDetails.is_special_location">
    <div class="h6 mb-1 font-weight-bold">Special Location</div>
    <div class="text-muted font-xs">
      <lib-salesonepro-icon name="check" class="text-success"></lib-salesonepro-icon>
    </div>
  </li>
  <li class="col" *ngIf="artworkDetails.is_match_tone_charge">
    <div class="h6 mb-1 font-weight-bold">Match Tone Charge</div>
    <div class="text-muted font-xs">
      <lib-salesonepro-icon name="check" class="text-success"></lib-salesonepro-icon>
    </div>
  </li>
  <li class="col" *ngIf="artworkDetails.is_neon_thread">
    <div class="h6 mb-1 font-weight-bold">Neon Thread</div>
    <div class="text-muted font-xs">
      <lib-salesonepro-icon name="check" class="text-success"></lib-salesonepro-icon>
    </div>
  </li>
  <li class="col" *ngIf="artworkDetails.is_tape_edit">
    <div class="h6 mb-1 font-weight-bold">Tape Edit</div>
    <div class="text-muted font-xs">
      <lib-salesonepro-icon name="check" class="text-success"></lib-salesonepro-icon>
    </div>
  </li>
</ul>
<div class="custom-scroll p-3 border">
  <div class="scrollable">
    <table class="w-100 border-bottom stitch-table" *ngIf="artworkDetails.thread_details">
      <thead>
        <tr>
          <th class="p-2">#</th>
          <th class="p-2">Thread Brand</th>
          <th class="p-2">Color Code</th>
          <th class="p-2">Color Description</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of artworkDetails.thread_details; let i = index">
          <td class="p-1 pt-2">{{i+1}}</td>
          <td class="p-1 pt-2">{{row.color_type.data.swatch_text}}</td>
          <td class="p-1 pt-2" width="35%">
            <!-- <div *ngIf="item.thread_color.colorcode" class="color_swatch-block"
              [ngStyle]="{background: item.thread_color.colorcode}"></div> -->
              {{row.color.data.color_name}}
          </td>
          <td class="p-1 pt-2">{{row.color_description}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<ng-container *ngFor="let item of lineTextList">
  <ng-container *ngIf="artworkDetails[item.id]">
    <div class="row">
      <div class="col-md-12 mt-2">
        <div class="title-bg py-2 px-3">{{item.label}}</div>
      </div>
    </div>
    <div class="col-md-12 border">
      <ul class="logo-nav row mt-3">
        <li class="col">
          <div class="h6 mb-1 font-weight-bold">Text</div>
          <div class="text-muted font-xs">
            {{artworkDetails[item.id].text}}
          </div>
        </li>
        <li class="col">
          <div class="h6 mb-1 font-weight-bold">Lettering Size</div>
          <div class="text-muted font-xs">
            {{artworkDetails[item.id].lettering_size.data.swatch_text}}
          </div>
        </li>
        <li class="col">
          <div class="h6 mb-1 font-weight-bold">Lettering Style</div>
          <div class="text-muted font-xs">
            {{artworkDetails[item.id].lettering_style.data.swatch_text}}
          </div>
        </li>
        <li class="col">
          <div class="h6 mb-1 font-weight-bold">Case Style</div>
          <div class="text-muted font-xs">
            {{caseStyleLabel[artworkDetails[item.id].case_style]}}
          </div>
        </li>
      </ul>
      <ul class="logo-nav row" *ngIf="artworkDetails[item.id]['note']">
        <li class="col" *ngIf="artworkDetails[item.id]['note']">
          <div class="h6 mb-1 font-weight-bold">Notes</div>
          <div class="text-muted font-xs">{{artworkDetails[item.id]['note']}}</div>
        </li>
      </ul>
      <div class="custom-scroll p-3 border mb-3"  *ngIf="artworkDetails[item.id]['thread_details'].length > 0">
        <table class="w-100 border-bottom stitch-table">
          <thead>
            <tr>
              <th class="p-2" width="27%">Color Type</th>
              <th class="p-2" width="35%">Color Code</th>
              <th class="p-2" width="30%">Color Description</th>
            </tr>
          </thead>
        </table>
        <table class="w-100 stitch-table">
          <tbody>
            <tr *ngFor="let row of artworkDetails[item.id]['thread_details']">
              <td class="p-1 pt-2" width="27%">{{row.color_type.data.swatch_text}}</td>
              <td class="p-1 pt-2" width="35%">
                <!-- <div *ngIf="artworkDetails.edge_color.thread_color.colorcode" class="color_swatch-block"
                  [ngStyle]="{background: artworkDetails.edge_color.thread_color.colorcode}"></div> -->
                  {{row.color.data.color_name}}
              </td>
              <td class="p-1 pt-2" width="30%">{{row.color_description}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
</ng-container>