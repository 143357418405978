<div id="http-loader" *ngIf="isSpinnerVisible">
  <div class="loader-bg">
    <!--sk-cube-grid-->
    <div class="sk-cube-grid colored-parent" *ngIf="spinner === Spinkit.skCubeGrid">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
    <!--sk-rotating-plane-->
    <div class="sk-rotating-plane colored-parent" *ngIf="spinner === Spinkit.skRotatingPlane"></div>
    <!--sk-double-bounce-->
    <div class="sk-double-bounce colored-parent" *ngIf="spinner === Spinkit.skDoubleBounce">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
    <!--sk-wave-->
    <div class="sk-wave colored-parent" *ngIf="spinner === Spinkit.skWave">
      <div class="sk-rect sk-rect1"></div>
      <div class="sk-rect sk-rect2"></div>
      <div class="sk-rect sk-rect3"></div>
      <div class="sk-rect sk-rect4"></div>
      <div class="sk-rect sk-rect5"></div>
    </div>
    <!--sk-wandering-cubes-->
    <div class="sk-wandering-cubes colored-parent" *ngIf="spinner === Spinkit.skWanderingCubes">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
    </div>
    <!--sk-spinner-pulse-->
    <div class="sk-spinner sk-spinner-pulse colored-parent" *ngIf="spinner === Spinkit.skSpinnerPulse"></div>
    <!--sk-chasing-dots-->
    <div class="sk-chasing-dots colored-parent" *ngIf="spinner === Spinkit.skChasingDots">
      <div class="sk-child sk-dot1"></div>
      <div class="sk-child sk-dot2"></div>
    </div>
    <!--sk-three-bounce-->
    <div class="sk-three-bounce colored-parent" *ngIf="spinner === Spinkit.skThreeBounce">
      <div class="sk-child sk-bounce1"></div>
      <div class="sk-child sk-bounce2"></div>
      <div class="sk-child sk-bounce3"></div>
    </div>
    <!-- material-line -->
    <div class="sk-line-material colored-parent" *ngIf="spinner === Spinkit.skLine">
      <div class="sk-child sk-bounce1"></div>
    </div>

  </div>
</div>