<app-header></app-header>
<div class="app-body">
  <!-- Main content -->
  <main class="main mt-2">
    <div class="theme-container">
      <app-breadcrumb ></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </main>
</div>