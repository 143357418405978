import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// components
import { ModalPopupOverlayRef } from '@salesonepro/components';

// services
import { AccountService } from '@core/account/account.service';
import { AuthService, ToasterService } from '@salesonepro/services';

// others
import { CustomValidator } from '@salesonepro/custom-validator';

@Component({
  selector: 'app-change-pswd-dialog',
  templateUrl: './change-pswd-dialog.component.html',
  styleUrls: ['./change-pswd-dialog.component.scss'],
})
export class ChangePswdDialogComponent {
  changePswdForm: FormGroup;
  invalidPswdForm: boolean;
  isLoading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private toasterService: ToasterService,
    public dialogRef: ModalPopupOverlayRef<ChangePswdDialogComponent>,
    private authService: AuthService,
    private router: Router,
    private el: ElementRef
  ) {
    this.buildForm();
  }

  buildForm() {
    this.changePswdForm = this.formBuilder.group(
      {
        current_password: ['', [Validators.required]],
        new_password: ['', [Validators.required]],
        confirm_password: ['', [Validators.required]],
      },
      {
        validator: CustomValidator.confirmPassword.bind(this),
      }
    );
  }

  // Method is for changing the password for the user.
  onClickChangePassword() {
    this.invalidPswdForm = true;
    if (this.changePswdForm.valid) {
      this.isLoading = true;
      this.invalidPswdForm = false;
      if (
        this.changePswdForm.value.current_password.trim() ===
        this.changePswdForm.value.new_password
      ) {
        this.toasterService.error(
          'Both Current Password and New Password should be different'
        );
        this.isLoading = false;
        return;
      }
      this.accountService
        .changeUserPassword(this.changePswdForm.value)
        .subscribe(
          (response) => {
            this.toasterService.success('Password changed successfully');
            this.isLoading = false;
            this.dialogRef.close(null);
            this.logout();
          },
          (err) => {
            this.isLoading = false;
            this.toasterService.error(err[0]);
            if (err[0] === 'Invalid current password') {
              this.toasterService.error('Invalid current password');
            }
          }
        );
    } else {
      this.handleErrorFocus();
    }
  }

  handleErrorFocus() {
    const target = this.el.nativeElement.querySelector('.ng-invalid:not(form)');
    if (target) {
      target.focus();
    }
  }

  logout() {
    this.authService.purgeAuth();
    this.router.navigate(['/', 'login']);
  }
}
