import { Injectable, Injector, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';
import fernet from 'fernet';

import { ToasterService } from './services/toaster.service';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private environment;
  constructor(
    private inj: Injector,
    private router: Router,
    private authService: AuthService,
    private storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject('environment') environment
  ) {
    this.environment = environment;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.inj.get(AuthService); // authservice is an angular service
    const jwtToken = authService.getToken();

    if (req.url.indexOf(this.environment.rocketShipServerUrl) === -1) {
      if (jwtToken && !req.headers.has('Authorization')) {
        req = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + jwtToken)
        });
      }
    }

    if (typeof this.authService.getCaptchaToken() === 'string') {
      req = req.clone({
        setHeaders: {
          RECAPTCHA: `${this.authService.getCaptchaToken()}`
        }
      });
    }

    let buildKey = null;
    if (typeof this.authService.getBuildKey() === 'string') {
      buildKey = this.authService.getBuildKey();
    }


    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (isPlatformBrowser(this.platformId) && this.environment.production) {
            if (event.headers.get('BUILD-KEY') && buildKey) {
              if (buildKey !== event.headers.get('BUILD-KEY')) {
                this.authService.saveBuildKey(event.headers.get('BUILD-KEY'));
                if (window.navigator && navigator.serviceWorker) {
                  navigator.serviceWorker.getRegistrations()
                    .then(function (registrations) {
                      for (const registration of registrations) {
                        registration.unregister();
                      }
                      document.location.reload();
                    });
                }
              }
            } else if (event.headers.get('BUILD-KEY') && buildKey !== event.headers.get('BUILD-KEY')) {
              this.authService.saveBuildKey(event.headers.get('BUILD-KEY'));
            }
          }
        }
      }),
      map(resp => {
        if (resp instanceof HttpResponse) {
          return this.modifyResponse(resp);
        }
        return resp;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          let newHttpErrorResponse = error;
          if (error.error && error.error['json']) {
            const secret = new fernet.Secret(this.storage.enp());
            const toke = new fernet.Token({
              secret: secret,
              token: error.error['json'],
              ttl: 0
            });
            const str = toke.decode();
            newHttpErrorResponse = new HttpErrorResponse({
              error: JSON.parse(str),
              headers: error.headers,
              status: error.status,
              statusText: error.statusText,
              url: error.url
            });
          }
          switch ((<HttpErrorResponse>newHttpErrorResponse).status) {
            case 400:
              return this.handle400Error(newHttpErrorResponse);
            case 401:
              authService.collectFailedRequest(req);
              return this.handle401Error(newHttpErrorResponse);
            case 403:
              return this.handle403Error(newHttpErrorResponse);
            case 404:
              return this.handle404Error(newHttpErrorResponse, req.url);
            case 429:
              return this.handle429Error(newHttpErrorResponse);
            case 500:
              return this.handle500Error(newHttpErrorResponse);
            case 502:
              return this.handle502Error(newHttpErrorResponse);
            default:
              return observableThrowError(newHttpErrorResponse);
          }
        } else {
          return observableThrowError(error);
        }
      }));

  }

  modifyResponse(resp) {
    if (resp.body && resp.body['json']) {
      const secret = new fernet.Secret(this.storage.enp());
      const toke = new fernet.Token({
        secret: secret,
        token: resp.body['json'],
        ttl: 0
      });
      const str = toke.decode();
      const dec = JSON.parse(str);
      return resp.clone({
        body: dec
      });
    }
    return resp;
  }

  handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.detail && error.error.detail === 'Authentication credentials were not provided.') {
      return this.logoutUser();
    }

    return observableThrowError(error);
  }

  handle401Error(error) {
    if (error && error.status === 401 && error.error && error.error.detail) {
      const errorMsg = error.error.detail;
      if (errorMsg === 'Authentication credentials were not provided.') {
        return this.logoutUser();
      } else {
        const toastr = this.inj.get(ToasterService);
        toastr.error(errorMsg);
      }
    }

    return observableThrowError(error);
  }

  handle403Error(error) {
    if (error && error.status === 403 && error.error && error.error.detail) {
      const errorMsg = error.error.detail;
      if (errorMsg === 'Signature has expired.') {
        return this.logoutUser();
      } else {
        const toastr = this.inj.get(ToasterService);
        toastr.error(errorMsg);
      }
    } else {
      const authService = this.inj.get(AuthService);
      const jwtToken = authService.getToken();
      if (!jwtToken || authService.isTokenExpired()) {
        return this.logoutUser();
      } else {
        if (error.statusText && error.statusText === 'Forbidden') {
          const toastr = this.inj.get(ToasterService);
          toastr.error('Forbidden');
        }
      }
    }

    return observableThrowError(error);
  }

  handle404Error(error, url) {
    return observableThrowError(error);
  }

  handle500Error(error) {
    let errorMsg = error.error;
    if (error.status === 0 || error.status === 500) {
      errorMsg = error.statusText;
    }
    const toastr = this.inj.get(ToasterService);
    toastr.error(errorMsg);

    return observableThrowError(error);
  }

  handle502Error(error) {
    const errorObj = error;
    error['error'] = {
      'message': 'The API server is temporarily unavailable. Please try again after sometime.'
    };

    return observableThrowError(errorObj);
  }

  handle429Error(error) {
    this.authService.isCaptchaRequiredSubject.next(true);
    const errorObj = error;
    if (error && error['statusText'] && error['statusText'] === 'Too Many Requests') {
      error['error'] = {
        'message': 'You have tried to sign in too many times with an incorrect email or password.'
      };
    }
    return observableThrowError(errorObj);
  }

  logoutUser() {
    const authService = this.inj.get(AuthService); // authservice is an angular service

    // Remove sessions and route to the login page
    authService.purgeAuth();
    this.router.navigate(['/login']);
    return observableThrowError('');
  }
}
