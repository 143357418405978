import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTimestampedDate'
})
export class FormatTimestampedDatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value) {
      var datePipe = new DatePipe('en-US');
      const datevalue = [];
      datevalue[0] =datePipe.transform(value, 'M/d/yy');
      datevalue[1] =datePipe.transform(value, 'h:mm a') + ' ' + 'PST';
      return datevalue;
  }
}
}
