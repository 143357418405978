<ng-container *ngIf="!loadingDecorationOptions">
  <ng-container formGroupName="data">
    <ng-container [formGroupName]="decorationMethod">
      <div class="row">
        <div class="col-md-12 mt-2">
          <div class="fw-bold border-bottom py-2">Enter Emblem Specifications</div>
        </div>
      </div>
      <div class="row"
        *ngIf="decorationSchemaKeys.includes('size') || customWidthHeightList.includes(this.decorationMethod)">
        <div class="col-md-3 my-2" *ngIf="decorationSchemaKeys.includes('size')">
          <label class="font-xs mb-2">Size <small class="text-danger">*</small></label>
          <ng-select placeholder="Select Size" [ngClass]="{'disabled': isView}" formControlName="size" [items]="decorationOptions['size']"
            bindLabel="data.value" bindValue="id" labelForId="data.value" (change)="onSizeChange()">
          </ng-select>
          <app-validation-errors [submitted]="invalidForm" [control]="form.get('size')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="size">
          </app-server-validation-error>
        </div>
        <ng-container *ngIf="isSizeOther">
          <div class="col-md-3 my-2">
            <label class="font-xs mb-2">Custom Die Width <small class="text-danger">*</small></label>
            <input type="number" [min]="1" class="form-control" formControlName="custom_die_width">
            <app-validation-errors [submitted]="invalidForm" [control]="form.get('custom_die_width')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="custom_die_width">
            </app-server-validation-error>
          </div>
          <div class="col-md-3 my-2">
            <label class="font-xs mb-2">Custom Die Height <small class="text-danger">*</small></label>
            <input type="number" [min]="1" class="form-control" formControlName="custom_die_height">
            <app-validation-errors [submitted]="invalidForm" [control]="form.get('custom_die_height')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="custom_die_height">
            </app-server-validation-error>
          </div>
        </ng-container>
      </div>
      <div class="row" *ngIf="showEmblemShape">
        <div class="col-md-3 my-2">
          <label class="font-xs mb-2">Emblem Shape <small class="text-danger">*</small></label>
          <select class="form-select" formControlName="emblem_shape">
            <option value="">Select</option>
            <ng-container *ngFor="let item of decorationOptions['emblem_shape']">
              <option [value]="item.id">{{item.data.swatch_text}}</option>
            </ng-container>
          </select>
          <app-validation-errors [submitted]="invalidForm" [control]="form.get('emblem_shape')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="emblem_shape">
          </app-server-validation-error>
        </div>
      </div>
      <div class="row" *ngIf="decorationSchemaKeys.includes('fabric_type')">
        <div class="col-md-3 my-2">
          <label class="font-xs mb-2">Fabric Type <small class="text-danger">*</small></label>
          <select class="form-select" formControlName="fabric_type" (change)="onFabricTypeChange()">
            <option value="">Select</option>
            <ng-container *ngFor="let item of decorationOptions['fabric_type']">
              <option [value]="item.id">{{item.data.swatch_text}}</option>
            </ng-container>
          </select>
          <app-validation-errors [submitted]="invalidForm" [control]="form.get('fabric_type')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="fabric_type">
          </app-server-validation-error>
        </div>
        <div class="col-md-3 my-2">
          <label class="font-xs mb-2">Fabric Color <small class="text-danger">*</small></label>
          <ng-select [items]="fabricColorArray" bindValue="id" [groupBy]="groupByFn"
            bindLabel="data.color_name" formControlName="fabric_color" (change)="onFabricColorChange()">
            <ng-template ng-label-tmp let-item="item">
              <ng-container *ngIf="item && item.data">
                <div class="float-start border p-2 me-2" [style.backgroundColor]="item.data.hex_code">
                </div>
                {{item.data.color_name}}
              </ng-container>
              <ng-container *ngIf="!item.label && !item.data">
                Select
              </ng-container>
            </ng-template>
            <ng-template ng-optgroup-tmp let-item="item">
              {{item['data.color_name']}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div class="float-start border p-2 me-2" [style.backgroundColor]="item.data.hex_code">
              </div>
              {{item.data.color_name}}
            </ng-template>
          </ng-select>
          <app-validation-errors [submitted]="invalidForm" [control]="form.get('fabric_color')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="fabric_color">
          </app-server-validation-error>
        </div>
        <div class="col-md-3 my-2" *ngIf="isFabricColorOther">
          <label class="font-xs mb-2">Custom Color <small class="text-danger">*</small></label>
          <input class="form-control" formControlName="fabric_color_other" type="text">
          <app-validation-errors fieldName="Color" [submitted]="invalidForm" [control]="form.get('fabric_color_other')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="fabric_color_other">
          </app-server-validation-error>
        </div>
      </div>
      <ng-container *ngIf="decorationSchemaKeys.includes('border_type')">
        <div class="row">
          <div class="col-md-3 my-2">
            <label class="font-xs mb-2">Border Type <small class="text-danger">*</small></label>
            <select class="form-select" formControlName="border_type" (change)="onBorderTypeChange()">
              <option value="">Select</option>
              <ng-container *ngFor="let item of decorationOptions['border_type']">
                <option [value]="item.id">{{item.data.swatch_text}}</option>
              </ng-container>
            </select>
            <app-validation-errors [submitted]="invalidForm" [control]="form.get('border_type')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="border_type">
            </app-server-validation-error>
          </div>
          <ng-container *ngIf="form.get('border_type').value !== ''">
            <div class="col-md-3 my-2" *ngIf="decorationSchemaKeys.includes('border_color_type')">
              <label class="font-xs mb-2">Border Color Type <small class="text-danger">*</small></label>
              <select class="form-select" formControlName="border_color_type"
                (change)="onBorderColorTypeChange()">
                <option value="">Select</option>
                <ng-container *ngFor="let item of decorationOptions['border_color_type']">
                  <option [value]="item.id">{{item.data.swatch_text}}</option>
                </ng-container>
              </select>
              <app-validation-errors [submitted]="invalidForm" [control]="form.get('border_color_type')">
              </app-validation-errors>
              <app-server-validation-error [errors]="error" key="border_color_type">
              </app-server-validation-error>
            </div>
            <div class="col-md-3 my-2" *ngIf="decorationSchemaKeys.includes('border_color')">
              <label class="font-xs mb-2">Border Color <small class="text-danger">*</small></label>
              <ng-select (change)="onBorderColorChange()" [items]="borderColorArray" bindValue="id"
                [groupBy]="groupByFn" bindLabel="data.color_name" formControlName="border_color">
                <ng-template ng-label-tmp let-item="item">
                  <ng-container *ngIf="item && item.data">
                    <div class="float-start border p-2 me-2" [style.backgroundColor]="item.data.hex_code">
                    </div>
                    {{item.data.color_name}}
                  </ng-container>
                  <ng-container *ngIf="!item.label && !item.data">
                    Select
                  </ng-container>
                </ng-template>
                <ng-template ng-optgroup-tmp let-item="item">
                  {{item['data.color_name']}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div class="float-start border p-2 me-2" [style.backgroundColor]="item?.data?.hex_code">
                  </div>
                  {{item?.data?.color_name}}
                </ng-template>
              </ng-select>
              <app-validation-errors [submitted]="invalidForm" [control]="form.get('border_color')">
              </app-validation-errors>
              <app-server-validation-error [errors]="error" key="border_color">
              </app-server-validation-error>
            </div>
            <div class="col-md-3 my-2" *ngIf="isBorderColorOther">
              <label class="font-xs mb-2">Custom Color <small class="text-danger">*</small></label>
              <input class="form-control" formControlName="border_color_other" type="text">
              <app-validation-errors fieldName="Color" [submitted]="invalidForm"
                [control]="form.get('border_color_other')">
              </app-validation-errors>
              <app-server-validation-error [errors]="error" key="border_color_other">
              </app-server-validation-error>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="decorationSchemaKeys.includes('garment_type')">
        <div class="row">
          <div class="col-md-3 my-2">
            <label class="font-xs mb-2">Garment Type <small class="text-danger">*</small></label>
            <select class="form-select" formControlName="garment_type" (change)="onGarmentTypeChange()">
              <option value="">Select</option>
              <ng-container *ngFor="let item of decorationOptions['garment_type']">
                <option [value]="item.id">{{item.data.swatch_text}}</option>
              </ng-container>
            </select>
            <app-validation-errors [submitted]="invalidForm" [control]="form.get('garment_type')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="garment_type">
            </app-server-validation-error>
          </div>
          <div class="col-md-3 my-2" *ngIf="isGarmentTypeOther">
            <label class="font-xs mb-2">Custom Garment Type <small class="text-danger">*</small></label>
            <input class="form-control" formControlName="garment_type_other" type="text">
            <app-validation-errors fieldName="Garment Type" [submitted]="invalidForm"
              [control]="form.get('garment_type_other')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="garment_type_other">
            </app-server-validation-error>
          </div>
          <div class="col-md-3 my-2">
            <label class="font-xs mb-2">Garment Content <small class="text-danger">*</small></label>
            <select class="form-select" formControlName="garment_content"
              (change)="onGarmentContentChange()">
              <option value="">Select</option>
              <ng-container *ngFor="let item of decorationOptions['garment_content']">
                <option [value]="item.id">{{item.data.swatch_text}}</option>
              </ng-container>
            </select>
            <app-validation-errors [submitted]="invalidForm" [control]="form.get('garment_content')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="garment_content">
            </app-server-validation-error>
          </div>
          <div class="col-md-3 my-2" *ngIf="isGarmentContentOther">
            <label class="font-xs mb-2">Custom Garment Content <small class="text-danger">*</small></label>
            <input class="form-control" formControlName="garment_content_other" type="text">
            <app-validation-errors fieldName="Garment Content" [submitted]="invalidForm"
              [control]="form.get('garment_content_other')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="garment_content_other">
            </app-server-validation-error>
          </div>
          <div class="col-md-3 my-2">
            <label class="font-xs mb-2">Garment Color <small class="text-danger">*</small></label>
            <select class="form-select" formControlName="garment_color"
              (change)="onGarmentColorChange()">
              <option value="">Select</option>
              <ng-container *ngFor="let item of decorationOptions['garment_color']">
                <option [value]="item.id">{{item.data.swatch_text}}</option>
              </ng-container>
            </select>
            <app-validation-errors [submitted]="invalidForm" [control]="form.get('garment_color')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="garment_color">
            </app-server-validation-error>
          </div>
          <div class="col-md-3 my-2" *ngIf="isGarmentColorOther">
            <label class="font-xs mb-2">Custom Color <small class="text-danger">*</small></label>
            <input class="form-control" formControlName="garment_color_other" type="text">
            <app-validation-errors fieldName="Color" [submitted]="invalidForm"
              [control]="form.get('garment_color_other')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="garment_color_other">
            </app-server-validation-error>
          </div>
        </div>
      </ng-container>
      <div class="row" *ngIf="decorationSchemaKeys.includes('font_type') && !isInsert">
        <div class="col-md-3 my-2">
          <label class="font-xs mb-2">Font Type <small class="text-danger">*</small></label>
          <select class="form-select" formControlName="font_type">
            <option value="">Select</option>
            <ng-container *ngFor="let item of decorationOptions['font_type']">
              <option [value]="item.id">{{item.data.name}}</option>
            </ng-container>
          </select>
          <app-validation-errors [submitted]="invalidForm" [control]="form.get('font_type')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="font_type">
          </app-server-validation-error>
        </div>
      </div>
      <div class="row" *ngIf="decorationSchemaKeys.includes('finish')">
        <div class="col-md-3 my-2">
          <label class="font-xs mb-2">Finish <small class="text-danger">*</small></label>
          <select class="form-select" formControlName="finish">
            <option value="">Select</option>
            <ng-container *ngFor="let item of decorationOptions['finish']">
              <option [value]="item.id">{{item.data.swatch_text}}</option>
            </ng-container>
          </select>
          <app-validation-errors [submitted]="invalidForm" [control]="form.get('finish')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="finish">
          </app-server-validation-error>
        </div>
      </div>
      <div class="row" *ngIf="decorationSchemaKeys.includes('laundry_type')">
        <div class="col-md-3 my-2">
          <p class="font-weight-bold">Select Laundry Type</p>
          <p class="mb-0">
            <ng-container *ngFor="let item of decorationOptions['laundry_type']">
              <div class="form-check form-check-inline">
                <input type="radio" id="laundry_type{{item.id}}" [value]="item.id" (change)="onLaundryChange(item.id)"
                  [checked]="selectedLaundryType === item.id" name="laundry_type" class="form-check-input">
                <label class="form-check-label" for="laundry_type{{item.id}}">{{item.data.swatch_text}}</label>
              </div>
            </ng-container>
            <app-validation-errors [submitted]="invalidForm" [control]="form.get('laundry_type')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="laundry_type">
            </app-server-validation-error>
          </p>
        </div>
      </div>
      <div class="row" *ngIf="decorationSchemaKeys.includes('fold')">
        <div class="col-md-3 my-2">
          <label class="font-xs mb-2">Fold <small class="text-danger">*</small></label>
          <select class="form-select" formControlName="fold">
            <option value="">Select</option>
            <ng-container *ngFor="let item of decorationOptions['fold']">
              <option [value]="item.id">{{item.data.swatch_text}}</option>
            </ng-container>
          </select>
          <app-validation-errors [submitted]="invalidForm" [control]="form.get('fold')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="fold">
          </app-server-validation-error>
        </div>
      </div>
      <div class="row" *ngIf="decorationSchemaKeys.includes('backing_type')">
        <div class="col-md-3 my-2">
          <label class="font-xs mb-2">Backing Type <small class="text-danger">*</small></label>
          <select class="form-select" formControlName="backing_type">
            <option value="">Select</option>
            <ng-container *ngFor="let item of decorationOptions['backing_type']">
              <option [value]="item.id">{{item.data.swatch_text}}</option>
            </ng-container>
          </select>
          <app-validation-errors [submitted]="invalidForm" [control]="form.get('backing_type')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="backing_type">
          </app-server-validation-error>
        </div>
      </div>
      <div class="row" *ngIf="decorationSchemaKeys.includes('attachements')">
        <div class="col-md-3 my-2">
          <label class="font-xs mb-2">Attachments <small class="text-danger">*</small></label>
          <select class="form-select" formControlName="attachements">
            <option value="">Select</option>
            <ng-container *ngFor="let item of decorationOptions['attachements']">
              <option [value]="item.id">{{item.data.swatch_text}}</option>
            </ng-container>
          </select>
          <app-validation-errors [submitted]="invalidForm" [control]="form.get('attachements')">
          </app-validation-errors>
          <app-server-validation-error [errors]="error" key="attachements">
          </app-server-validation-error>
        </div>
      </div>
      <ng-container *ngIf="decorationSchemaKeys.includes('lines')">
        <div class="row">
          <div class="col-md-12 mt-2 font-weight-bold">
            Text
            <hr class="my-2">
          </div>
        </div>
        <ng-container *ngFor="let ctrl of form.get('lines').controls; let k = index">
          <div class="row">
            <div class="col-md-3 my-2">
              <label class="font-xs mb-2">Line {{k+1}}<small class="text-danger">*</small></label>
              <input type="text" class="form-control" [formControl]="ctrl">
              <app-validation-errors fieldName="Text" [submitted]="invalidForm" [control]="ctrl">
              </app-validation-errors>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="col-md-12">
            <a class="text-link cursor-pointer font-weight-bold" (click)="addLineText()">+ Add Another Line Text</a>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="colorDetailsExist">
        <div class="row">
          <div class="col-md-12 mt-2 font-weight-bold">
            Internal{{decorationSchemaKeys.includes('thread_details') ? ' Thread' : ''}} Colors
            <hr class="my-2">
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 my-2">
            <label class="font-xs mb-2">No of Colors <small class="text-danger">*</small></label>
            <input type="number" min="0" class="form-control" formControlName="no_of_colors">
            <app-validation-errors [submitted]="invalidForm" [control]="form.get('no_of_colors')">
            </app-validation-errors>
            <app-server-validation-error [errors]="error" key="no_of_colors">
            </app-server-validation-error>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9 mt-2 custom-scroll">
            <table class="table bg-gray mb-0" *ngIf="logoThreadDetails && logoThreadDetails.length > 0">
              <thead>
                <tr>
                  <th width="5%">#</th>
                  <th width="20%"> Color Type</th>
                  <th width="35%" translate>Color Code</th>
                  <th width="30%" translate>Color Description</th>
                </tr>
              </thead>
            </table>
            <div [ngClass]="{'table-wrapper-scroll-y': (logoThreadDetails && logoThreadDetails.length > 5)}">
              <table class="table border patch-color" *ngIf="logoThreadDetails">
                <tbody [formArrayName]="threadDetailsKey">
                  <ng-container *ngFor="let threadData of threadDetailsFormData.controls; let i=index">
                    <tr [formGroupName]="i">
                      <td width="5%">{{i+1}}</td>
                      <td width="20%" formGroupName="color_type">
                        <select class="form-select" formControlName="id"
                          (change)="threadChange($event, 'color_type', i)">
                          <option *ngFor="let item of threadTypeArray" [value]="item.id">{{item.data.swatch_text}}
                          </option>
                          
                        </select>
                        <app-validation-errors [submitted]="formValidation"
                          [control]="threadData.get(['color_type', 'id'])"></app-validation-errors>
                      </td>
                      <td width="35%" class="position-relative" formGroupName="color">
                        <ng-select (change)="onThreadColorSelect($event, i)" [items]="threadColorArray[i]"
                          bindValue="id" [groupBy]="groupByFn" bindLabel="data.color_name" formControlName="id"
                          placeholder="Select Color" class="color-code-wrap">
                          <ng-template ng-label-tmp let-item="item">
                            <ng-container *ngIf="item && item.data">
                              <div class="float-start border p-2 me-2" [style.backgroundColor]="item.data.hex_code">
                              </div>
                              {{item.data.color_name}}
                            </ng-container>
                            <ng-container *ngIf="!item.label && !item.data">
                              Select
                            </ng-container>
                          </ng-template>
                          <ng-template ng-optgroup-tmp let-item="item">
                            {{item['data.color_name']}}
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item">
                            <div class="float-start border p-2 me-2" [style.backgroundColor]="item.data.hex_code">
                            </div>
                            {{item.data.color_name}}
                          </ng-template>
                        </ng-select>
                        <app-validation-errors fieldName="Thread Color" [submitted]="formValidation"
                          [control]="threadData.get(['color', 'id'])"></app-validation-errors>
                      </td>
                      <td width="30%">
                        <input type="text" class="form-control" formControlName="color_description"
                          (input)="threadChange($event, 'color_description', i)" />
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isInsert && insertTextData.length > 0">
      <div class="row">
        <div class="col-md-12 mt-2" >
          <div class="fw-bold border-bottom py-2" #insertBlock>Insert Information</div>
        </div>
      </div>
      <div class="row" *ngFor="let item of insertTextData">
        <div class="col-md-6">
          <app-order-shared-insert-list-details [item]="item"
            (insertClick)="onIsInsertClick($event)" (removeClick)="removeInsertData()"></app-order-shared-insert-list-details>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>