<div class="d-flex login-page-wrap align-items-center h-100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="login-form-wrap position-relative">
          <div class="login-form-logo">
            <img src="/assets/images/logo-login.png" class="mw-100" alt="" />
          </div>
          <ng-container *ngIf="!forgotPassword">
            <div class="login-wrap p-5">
              <div class="alert alert-danger" *ngIf="formattedErrors.length > 0">
                <span *ngFor="let error of formattedErrors">
                  <ng-container *ngIf="error !== '' && error !== 'invalid_grant'">
                    {{ error }}
                  </ng-container>
                </span>
              </div>
              <form [formGroup]="authForm" (ngSubmit)="submitForm()">
                <app-spinner type="normal" [isSpinnerVisible]="isSpinnerVisible"></app-spinner>
                <div class="form-group">
                  <div class="input-group">
                    <span class="input-group-text" id="username"> <i class="bi bi-person-fill"></i></span>
                    <input type="text" placeholder="Username/Email" formControlName="username" class="form-control"/>
                  </div>
                  <app-validation-errors [submitted]="invalidAuthForm" [control]="authForm.get('username')"></app-validation-errors>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <span class="input-group-text" id="password"><i class="bi bi-lock-fill"></i></span>
                    <input type="password" formControlName="password" placeholder="Password" class="form-control"
                      aria-describedby="password" />
                  </div>
                  <app-validation-errors [submitted]="invalidAuthForm" [control]="authForm.get('password')"></app-validation-errors>
                  <a (click)="onClickForgotPwsd()"
                    class="text-decoration-none btn-pointer fp-link font-sm text-end mt-1 d-block">Forgot
                    Password?</a>
                  <div *ngIf="formInvalid" class="col-md-6 mb-4 mt-2">
                    <app-recaptcha-form [formInvalid]="formInvalid"></app-recaptcha-form>
                  </div>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-block btn-login">Login
                  </button>
                  <div class="text-center or">or</div>
                  <a href="/request-login" class="text-decoration-none btn btn-outline-primary btn-request">Request a
                    Login</a>
                </div>
              </form>

            </div>
          </ng-container>

          <ng-container *ngIf="forgotPassword">
            <div class="forgot-wrap px-5 py-3">
              <div class="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                <h6 class="fw-bold">Forgot Your Password</h6>
                <a (click)="goBack()" class="btn-pointer text-decoration-none fw-500 goback"><i
                    class="bi bi-arrow-left"></i>Back</a>
              </div>
              <p class="font-sm">
                Please enter your email address below to receive a password reset link.
              </p>
              <form [formGroup]="forgotPasswordForm" (ngSubmit)="onClickRessetPwsd(forgotPasswordForm.value)">
                <div class="form-group">
                  <div class="input-group">
                    <span class="input-group-text" id="email"> <i class="bi bi-envelope-fill"></i></span>
                    <input formControlName="email" required type="text" placeholder="Email*" class="form-control"
                      aria-describedby="email" />
                  </div>
                  <app-validation-errors [submitted]="invalidForgotPswdForm"
                    [control]="forgotPasswordForm.get('email')"></app-validation-errors>
                </div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-block btn-login">Reset Password
                  </button>
                </div>
              </form>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>