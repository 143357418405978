/* eslint-disable @angular-eslint/component-selector */
import { HttpParams } from '@angular/common/http';
import { Component, NgZone, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Validators, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { CustomValidator } from '@salesonepro/custom-validator';
import { SharedService, StorageService } from '@salesonepro/services';
import { phoneNumberExtMask } from '@salesonepro/utils';

@Component({
  selector: 'app-ecommerce-core-shared-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnChanges {

  addressForm: FormGroup;
  form: FormGroup;

  countryList = [];
  stateList = [];
  mask = phoneNumberExtMask;
  isLoading: boolean;
  invalidAdrsForm: boolean;
  errors: any;

  defaultCountry = 'US';

  constructor(
    private parent: FormGroupDirective,
    private storage: StorageService,
    private ngZone: NgZone,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.formInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invalidFormData && this.invalidAdrsForm && this.addressForm) {
      Object.keys(this.addressForm.controls).forEach(c => {
        this.addressForm.get(c).markAsTouched();
        this.addressForm.get(c).updateValueAndValidity();
      });
    }
  }


  formInit() {
    this.getCountryState();
    this.getFormData();
    const currentUser = this.storage.retrieve('user');
    if (currentUser) {
      if (!this.addressForm.value.email) {
        this.addressForm.get('email').setValue(currentUser.email);
      }
      if (!this.addressForm.value.name) {
        this.addressForm.get('name').setValue(currentUser.name);
      }
    }
  }

  getFormData() {
    this.form = this.parent.form;
    this.addressForm = this.form;
    this.addressForm.addControl('id', new FormControl(''));
    this.addressForm.addControl('name', new FormControl('', [Validators.required]));
    this.addressForm.addControl('email', new FormControl('', [Validators.required, CustomValidator.email]));
    this.addressForm.addControl('company_name', new FormControl(''));
    this.addressForm.addControl('address_1', new FormControl('', [Validators.required]));
    this.addressForm.addControl('address_2', new FormControl(''));
    this.addressForm.addControl('city', new FormControl('', [Validators.required]));
    this.addressForm.addControl('state', new FormControl(''));
    this.addressForm.addControl('state_text', new FormControl(''));
    this.addressForm.addControl('country', new FormControl(this.defaultCountry, Validators.required));
    this.addressForm.addControl('zip', new FormControl('', [Validators.required]));
    this.addressForm.addControl('phone', new FormControl('', [Validators.required]));
  }

  getCountryState() {
    const arrCountryState = this.storage.retrieve('country-state-list');
    this.countryList = arrCountryState['countries'].filter((obj => [this.defaultCountry].includes(obj.code)));
    const country = this.countryList.find(obj => obj.code === this.defaultCountry);
    if (country) {
      this.stateList = country['states'] ? country['states'] : [];
    }
  }

  fillInAddress(addressData) {
    if (Object.keys(addressData).length) {
      this.ngZone.run(() => {
        this.addressForm.controls['address_1'].setValue(addressData.address_1);
        this.addressForm.controls['address_2'].setValue(addressData.address_2);
        this.addressForm.controls['city'].setValue(addressData.city);
        this.addressForm.controls['state'].setValue(addressData.state);
        this.addressForm.controls['zip'].setValue(addressData.zip);
        this.addressForm.controls['country'].setValue(addressData.country);
        this.onChangeCountry(addressData.country);
      });
    }
  }

  onChangeCountry(item) {
    if (item) {
      this.stateList = this.countryList.find(obj => obj.code === item).states;
    } else {
      this.stateList = [];
    }
    const stateControl = this.addressForm.controls['state'];
    const stateTextCtrl = this.addressForm.controls['state_text'];
    if (this.stateList.length === 0 && this.addressForm.controls['country'].value !== 'US') {
      stateControl.clearValidators();
      stateControl.updateValueAndValidity();
      stateTextCtrl.setValidators([Validators.required]);
      stateTextCtrl.updateValueAndValidity();
      stateControl.reset();
    } else {
      stateControl.setValidators([Validators.required]);
      stateControl.updateValueAndValidity();
      stateTextCtrl.clearValidators();
      stateTextCtrl.updateValueAndValidity();
      stateTextCtrl.reset();
    }
  }

  onChangeZipCode(zip) {
    if (this.addressForm.controls['country'].value === 'US' && zip.trim().length > 3) {
      let params = new HttpParams();
      params = params.append('zip', zip.trim());
      this.sharedService.getZipcodeDetails(params).subscribe((response) => {
        if (response && response.state) {
          this.addressForm.get('state').setValue(response.state);
        }
      });
    }
  }

  get name() { return this.addressForm.get('name'); }
  get email() { return this.addressForm.get('email'); }
  get address_1() { return this.addressForm.get('address_1'); }
  get address_2() { return this.addressForm.get('address_2'); }
  get city() { return this.addressForm.get('city'); }
  get state() { return this.addressForm.get('state'); }
  get state_text() { return this.addressForm.get('state_text'); }
  get country() { return this.addressForm.get('country'); }
  get zip() { return this.addressForm.get('zip'); }
  get phone() { return this.addressForm.get('phone'); }

}
