import { Component, OnChanges, Input, ElementRef, ViewChild, SimpleChanges } from '@angular/core';

// others
import { isEmpty, getLightColor } from '@salesonepro/utils';
@Component({
  selector: 'app-dst-preview',
  templateUrl: './dst-preview.component.html'
})
export class DstPreviewComponent implements OnChanges {
  @Input() clearCanvas: boolean;
  @Input() resetCanvas: boolean;
  @Input() dstInfo: any;
  @Input() dstColors: any;
  @Input() logoThreadDetails: any;
  @Input() threadColors: any;
  @Input() selectedIndex: number;

  @ViewChild('logoPreviewCanvas', {static: true}) logoPreviewCanvas: ElementRef;

  context: CanvasRenderingContext2D;
  canvasDrawStatus: boolean;
  focusChangeStatus: boolean;

  animateKey = 1;
  intervalListener: any;
  colorIndex: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dstInfo) {
      if (!isEmpty(this.dstInfo)) {
        this.drawDst();
      }
    }
    if (changes.dstColors && this.dstColors && !isEmpty(this.dstInfo)) {
      this.drawDst();
    }
    if (changes.clearCanvas && this.clearCanvas) {
      if (this.intervalListener) {
        clearInterval(this.intervalListener);
      }
      if (this.context) {
        const canvas = this.logoPreviewCanvas.nativeElement;
        this.context.clearRect(0, 0, canvas.width, canvas.height);
      }
    }

    if (changes.selectedIndex && Number.isInteger(this.selectedIndex) && this.dstInfo) {
      this.colorIndex = this.selectedIndex;
      this.focusChangeStatus = true;
      this.canvasDrawStatus = true;
      if (typeof this.intervalListener !== 'undefined' && this.intervalListener) {
        clearInterval(this.intervalListener);
        this.intervalListener = null;
      } else {
        this.focusChangeStatus = false;
      }
      if (this.canvasDrawStatus) {
        this.animateDraw();
        this.intervalListener = setInterval(() => {
          this.animateDraw();
        }, 150);
      }
    }

    if (changes.resetCanvas && this.resetCanvas) {
      this.dstDrawReset();
    }
  }

  drawDst() {
    if (this.logoPreviewCanvas) {
      const canvas = this.logoPreviewCanvas.nativeElement;
      canvas.width  = this.dstInfo.width ? parseFloat(this.dstInfo.width) : 0;
      canvas.height = this.dstInfo.height ? parseFloat(this.dstInfo.height) : 0;
      this.context = canvas.getContext('2d');
      const defaultColor = '#000';
      if (this.dstInfo.draw_params) {
        this.dstInfo.draw_params.forEach(drawItem => {
          let color = '#000';
          if (this.dstColors[drawItem.color_index]) {
            color = this.dstColors[drawItem.color_index];
          } else if (this.logoThreadDetails[drawItem.color_index] && this.logoThreadDetails[drawItem.color_index].color && this.logoThreadDetails[drawItem.color_index].color['data']) {
            const colorData = this.logoThreadDetails[drawItem.color_index].color;
            color = colorData['data']['hex_code'];
            this.dstColors[drawItem.color_index] = color;
          } else if (this.threadColors.length > 0) {
            color = this.threadColors[drawItem.color_index].data.hex_code !== '' ? this.threadColors[drawItem.color_index].data.hex_code : defaultColor;
            this.dstColors[drawItem.color_index] = color;
          }
          this.canvasDraw(drawItem, color);
        });
      }
    }
  }

  animateDraw() {
    if (this.dstInfo && this.dstInfo.color_stich_array && this.dstInfo.draw_params && this.logoPreviewCanvas) {
      this.canvasDrawStatus = false;
      const canvas = this.logoPreviewCanvas.nativeElement;
      this.context.clearRect(0, 0, canvas.width, canvas.height);
      if (this.dstInfo.draw_params) {
        this.dstInfo.draw_params.forEach((drawItem, index) => {
          let color = this.dstColors[drawItem.color_index] ? this.dstColors[drawItem.color_index] : '#000';
          const lighter = getLightColor(color, 0.2, false);
          if (this.animateKey === 1 && drawItem.color_index === this.colorIndex) {
            color = lighter;
          }
          this.canvasDraw(drawItem, color);

          if (this.dstInfo.draw_params.length === (index + 1)) {
            this.canvasDrawStatus = true;
          }
        });
        if (this.animateKey === 0) {
          this.animateKey = 1;
        } else {
          this.animateKey = 0;
        }
      }
    }
  }

  dstDrawReset() {
    if (typeof this.intervalListener !== 'undefined' && this.intervalListener && !this.focusChangeStatus) {
      clearInterval(this.intervalListener);
      this.intervalListener = null;
      this.colorIndex = null;
      if (this.dstInfo && this.dstInfo.color_stich_array && this.dstInfo.draw_params) {
        this.dstInfo.draw_params.forEach((drawItem) => {
          const color = this.dstColors[drawItem.color_index];
          this.canvasDraw(drawItem, color);
        });
        if (this.animateKey === 0) {
          this.animateKey = 1;
        } else {
          this.animateKey = 0;
        }
      }
    }
    this.focusChangeStatus = false;
  }

  canvasDraw(drawItem, color) {
    this.context.beginPath();
    this.context.moveTo(drawItem.x1, drawItem.y1);
    this.context.lineTo(drawItem.x2, drawItem.y2);
    this.context.strokeStyle = color;
    this.context.stroke();
  }
}
