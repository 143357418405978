import { Directive, ElementRef, OnInit, Output, EventEmitter, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SharedService } from '../services/shared.service';


declare var google: any;

@Directive({
  selector: '[GooglePlaces]'
})
export class GooglePlacesDirective implements OnInit {
  @Output() addressSelect: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;
  location_obj = {};

  constructor(
    elRef: ElementRef,
    private renderer2: Renderer2,
    private sharedService: SharedService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.element = elRef.nativeElement;
  }

  ngOnInit() {
    if (typeof(google) === 'undefined') {
      const isLoading = this.sharedService.isScriptLoadingSubject.value;
      if (!isLoading) {
        this.sharedService.isScriptLoadingSubject.next(true);
        const url = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB0uhD3cqnqi8Suz6tj_YGRXXf3-xwQyZE&libraries=places';
        this.loadScript(url).then(() => {
          this.sharedService.isScriptLoadingSubject.next(false);
          this.initPlaces();
          let arr = this.sharedService.pendingScriptInitListSubject.value;
          arr.forEach(element => {
            this.initPlacesUsingElement(element);
            arr = arr.filter(x => x !== element);
          })
          this.sharedService.pendingScriptInitListSubject.next(arr);
        });
      } else {
        const arr = this.sharedService.pendingScriptInitListSubject.value;
        arr.push(this.element);
        this.sharedService.pendingScriptInitListSubject.next(arr);
      }
    } else {
      this.sharedService.isScriptLoadingSubject.next(false);
      this.initPlaces();
    }
  }

  getFormattedAddress(place) {
    this.location_obj = {};
    if (place.formatted_address) {
      const split = place.formatted_address.split(', ');
      if (split.length === 4) {
        this.location_obj['city'] = split[1];
      }
    }
    place?.address_components?.forEach(item => {
      this.location_obj['formatted_address'] = place.formatted_address;
      this.location_obj['address_2'] = '';
      if (item['types'].indexOf('locality') > -1 && !this.location_obj['city']) {
        this.location_obj['city'] = item['long_name'];
      } else if (item['types'].indexOf('administrative_area_level_1') > -1) {
        this.location_obj['state'] = item['short_name'];
      } else if (item['types'].indexOf('street_number') > -1) {
        this.location_obj['address_1'] = item['short_name'];
      } else if (item['types'].indexOf('route') > -1) {
        if (this.location_obj['address_1']) {
          this.location_obj['address_1'] = `${this.location_obj['address_1']} ${item['long_name']}`;
        } else {
          this.location_obj['address_1'] = `${item['short_name']}`;
        }
      } else if (item['types'].indexOf('country') > -1) {
        this.location_obj['country'] = item['short_name'];
      } else if (item['types'].indexOf('postal_code') > -1) {
        this.location_obj['zip'] = item['short_name'];
      }
    });
    return this.location_obj;
  }

  initPlaces() {
    const autocomplete = new google.maps.places.Autocomplete(this.element, {
      componentRestrictions: { country: 'US' }
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.addressSelect.emit(this.getFormattedAddress(autocomplete.getPlace()));
    });
  }

  initPlacesUsingElement(element) {
    const autocomplete = new google.maps.places.Autocomplete(element, {
      componentRestrictions: { country: 'US' }
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.addressSelect.emit(this.getFormattedAddress(autocomplete.getPlace()));
    });
  }

  private loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.text = '';
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer2.appendChild(this.document.body, script);
    });
  }

}
