import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, ToasterService } from '@salesonepro/services';

@Injectable({
    providedIn: 'root'
})
export class PasswordGuardService  {
    constructor(
        public router: Router,
        private authService: AuthService,
        public toasterService: ToasterService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const idToken = this.authService.getToken();
        const tokenExpired = idToken ? this.authService.isTokenExpired() : true;
        if (idToken && !tokenExpired) {
            this.toasterService.error('You are already logged in');
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}