import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { map} from 'rxjs/operators';
import * as Rx from 'rxjs';

import { AuthService } from './auth.service';

export interface Message {
  message: string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private subject: Rx.Subject<MessageEvent>;
  public messages: Subject<Message>;

  constructor(
    private authService: AuthService,
    @Inject('environment')
    environment
  ) {
    const token = this.authService.getToken();
    const url = environment.socketUrl + '' + '/ws/notifications/?token=' + token;
    // this.messages = <Subject<Message>> this
    // .connect(url)
    // .pipe(
    //   map((response: MessageEvent): Message => {
    //     const data = JSON.parse(response.data);
    //     return data;
    //   })
    // );
  }

  public connect(url): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log('connected to ' + url);
    }
    return this.subject;
  }

  private create(url): Rx.Subject<MessageEvent> {
    const ws = new WebSocket(url);

    const observable = Rx.Observable.create(
    (obs: Rx.Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    const observer = {
        next: (data: Object) => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(data));
          }
        }
      };
      return Rx.Subject.create(observer, observable);
  }
}
