
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner.component';
import { SpinnerDirective } from './spinner.directive';


@NgModule({
  imports: [CommonModule],
  exports: [SpinnerComponent, SpinnerDirective],
  declarations: [SpinnerComponent, SpinnerDirective]
})
export class SpinnerModule {}
