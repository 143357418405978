
<ol class="breadcrumb mt-3 bg-grey">
  <li class="breadcrumb-item font-xs d-inline">
    <a [routerLink]="'/'">
      Home
    </a>
  </li>
  <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last=last>
    <li class="breadcrumb-item font-xs d-inline" [ngClass]="{active: last}">
      <a *ngIf="breadcrumb.status && !last" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
      <a *ngIf="!breadcrumb.status && !last" class="not-active">{{ breadcrumb.label }}</a>
      <span *ngIf="last">{{ breadcrumb.label }}</span>
    </li>
  </ng-template>
</ol>